import React, { useEffect, useState, useRef, useContext } from "react";
import styles from "./ActivityReportList.module.css";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import { useNavigate, Link } from "react-router-dom";
import { base_url, token_api } from "../../Utils/network";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import filterIcon from "../../Assets/filterIcon.png";
import CustomButton from "../../Component/CustomButton/CustomButton";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomSelect from "../../Component/CustomSelect/CustomSelect";
import CustomDate from "../../Component/CustomDate/CustomDate";
import editIcon from "../../Assets/edit_icon.svg";
import delete_icon from "../../Assets/delete_icon.svg";
import { width } from "@mui/system";
import pdfDownload from "../../Assets/pdfDownload.svg";
import blackpdfDownload from "../../Assets/blackDownload.svg";

import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import InputFields from "../../Component/InputFields/InputFields";
import { Button, useTheme } from "@mui/material";
import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";
import dayjs from "dayjs";
import dragDrop from "../../Assets/drag_drop.svg";
import {
  extractDateTime,
  formatDate,
  convertTo12HourFormat,
} from "../../Utils/helper";
import axios from "axios";
import { Validate } from "./Utility";
import { helperdayMonthYearFull } from "../../Utils/Utils";

import { useSelector } from "react-redux";
import MyMap from "../../Component/MyMap/MyMap";
import DurationPicker from "../../Component/DurationPicker/DurationPicker";
import DronePilot from "../../Component/DronePilot/DronePilot";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import DroneServiceBooking from "../../Component/DroneServiceBooking/DroneServiceBooking";
import customerImg from "../../Assets/pilotIcon.svg";
import farmImage from "../../Assets/farmImage.svg";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import CustomModal from "../../Component/CustomModal/CustomModal";

const ActivityReportList = () => {
  const { access_token } = useSelector((state) => state.auth);
  const { user_permissions } = useContext(UserCredsContext);

  const theme = useTheme();
  const { palette } = theme;

  const navigate = useNavigate();
  const kmlInput = useRef(null);
  const signedInput = useRef(null);
  const farmPhotoInput = useRef(null);
  const [dashoboardData, setDashboardData] = useState({});

  const [filters, setfilters] = useState({});
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [activityReportList, setActivityReportList] = useState([]);
  const [updateId, setUpdateId] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loc, updLoc] = useState({ lat: 0, lng: 0 });
  const [pilotSelected, setPilotSelected] = useState([]);
  // const [activityPincode,setAciti]
  const [serviceBookingSeleted, setServiceBookingSelected] = useState([]);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [exportData, setExportData] = useState({
    start_date: null,
    end_date: null,
  });

  const [formData, setFormData] = useState({
    acres_done_today: "",
    city: "",
    country: "",
    crop: "",
    customer_name: "",
    customer_email: "",
    customer_phone: "",
    date: null,
    district: "",
    line_1: "",
    line_2: "",
    liquid_used: "",
    pincode: "",
    plot_no: "",
    remark: "",
    spray_start_time: "",
    spray_end_time: "",
    state: "",
    tafget_acres_today: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [kmlFile, setKmlFile] = useState("");
  const [kmlFilePreview, setKmlFilePreview] = useState("");
  const [loading, setLoading] = useState(false);
  const [courseMedia, setCourseMedia] = useState([]);

  let [farmPhotoFiles, setFarmPhotoFiles] = useState([]);
  let [farmPhotosPreviews, setFarmPhotoPreviews] = useState([]);
  const [signedFile, setSignedFile] = useState("");
  const [signedFilePreview, setSignedFilePreview] = useState("");
  const [drawerType, setDrawerType] = useState("");
  const [activityView, setActivityView] = useState({});
  const [serviceBookingId, setServiceBookingId] = useState(null);
  const [serviceBookingDetails, setServiceBookingDetails] = useState({});
  const [serviceBookingList, setServiceBookingList] = useState([]);
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Activity Reports</div>,
  ];

  function handleDrop(e, type) {
    e.preventDefault();
    if (e?.target?.files) {
      // simple drop
      // type=c_media or banner

      if (type === "kml_file") {
        const imageItem = e?.target?.files[0];
        setKmlFile(imageItem);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setKmlFilePreview(imageUrl);
      } else if (type === "signed_log") {
        // banner
        const imageItem = e?.target?.files[0];
        setSignedFile(imageItem);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setSignedFilePreview(imageUrl);
      } else {
        // banner
        const imageItem = Array.from(e?.target?.files);
        setFarmPhotoFiles([...farmPhotoFiles, ...imageItem]);
        // const imageUrl = imageItem.map((item) => URL.createObjectURL(item));
        // setFarmPhotoPreviews([...farmPhotosPreviews, imageUrl]);
      }
    } else if (e?.dataTransfer?.files) {
      if (type === "kml_file") {
        const imageItem = e?.dataTransfer?.files[0];
        setKmlFile(imageItem);
        const imageUrl = URL.createObjectURL(e.dataTransfer.files[0]);
        setKmlFilePreview(imageUrl);
      } else if (type === "signed_log") {
        const imageItem = e?.dataTransfer?.files[0];
        setSignedFile(imageItem);
        const imageUrl = URL.createObjectURL(e.dataTransfer.files[0]);
        setSignedFilePreview(imageUrl);
      } else {
        const imageItem = Array.from(e?.dataTransfer?.files);
        setFarmPhotoFiles([...farmPhotoFiles, ...imageItem]);
        // const imageUrl = imageItem.map((item) => URL.createObjectURL(item));
        // setFarmPhotoPreviews([...farmPhotosPreviews, imageUrl]);
      }
    }
  }

  const handleClick = (type) => {
    if (type === "kml_file") {
      kmlInput.current.click();
    } else if (type === "signed_log") {
      signedInput.current.click();
    } else {
      farmPhotoInput.current.click();
    }
  };
  function handleDragOver(e) {
    e.preventDefault();
  }

  const handleOpenDeleteModal = (reportId) => {
    token_api
      .delete(`service/pilot/activity_report/${reportId}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Activity Report Deleted Successfully!");
          getActivityReportsListing();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };

  const handleViewActivityDetails = (item) => {
    setActivityView(item);
    // updLoc({
    //   lat: item.lat === undefined ? 0 : item.lat,
    //   lng: item.lng === undefined ? 0 : item.lng,
    // });
    updLoc({
      lat: item?.pilot_details?.pilot_address
        ? item?.pilot_details?.pilot_address?.lat
        : 0,
      lng: item?.pilot_details?.pilot_address
        ? item?.pilot_details?.pilot_address?.long
        : 0,
    });
    setServiceBookingId(item?.service_booking?.id);
    setDrawerType("viewActivity");
    setOpenDrawer(true);
  };

  const getServiceBookingDetails = (serviceBookingId) => {
    token_api
      .get(`service/booking/${serviceBookingId}`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          setServiceBookingDetails(response?.data?.data);
          // updLoc({
          //   ...loc,
          //   lat: response?.data?.data?.lat,
          //   lng: response?.data?.data?.long,
          // });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function getServiceBookingList() {
    token_api
      .get(`/service/booking?page_size=100`)
      .then((response) => {
        if (response) {
          let newRes = response?.data?.data.map((info) => {
            return { id: info?.id, title: info?.full_name };
          });
          setServiceBookingList(newRes);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getDashBoardData = () => {
    token_api
      .get(`service/activity/dashboard_data`)
      .then((response) => {
        setDashboardData(response?.data?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getServiceBookingList();
    getDashBoardData();
  }, []);

  console.log(dashoboardData, "haridashoboardData");

  useEffect(() => {
    if (serviceBookingSeleted[0]) {
      const {
        full_name,
        pincode,
        address_line_1,
        address_line_2,
        city,
        start_date,
        state,
        remark,
        plot_no,
        country,
        district,
        contact_no,
        lat,
        long,
      } = serviceBookingSeleted[0];
      setFormData({
        ...formData,
        customer_name: full_name,
        pincode: pincode,
        line_1: address_line_1,
        city: city,
        country: country,

        customer_phone: contact_no,
        date: dayjs(start_date),
        district: district,
        line_2: address_line_2,
        plot_no: plot_no,
        remark: remark,
        state: state,
      });
      updLoc({ lat: lat, lng: long });
    }
  }, [serviceBookingSeleted]);

  useEffect(() => {
    if (serviceBookingId) {
      getServiceBookingDetails(serviceBookingId);
    }
  }, [serviceBookingId]);
  const handleRouteToServiceBooking = (serviceBookingId) => {
    navigate(`/bookings/service-booking-details/${serviceBookingId}`);
  };
  const getActivityReportsListing = async (filterObject) => {
    try {
      const fetchList = await token_api.get(`service/pilot/activity_report`, {
        params: { ...filterObject },
        paramsSerializer: {
          indexes: null,
        },
      });
      if (fetchList?.status !== 200) {
        throw new Error("Network Error");
      }
      setPaginationData(fetchList?.data);

      let modifiedArray = fetchList?.data?.data?.map((item) => {
        return {
          activityId: (
            <div
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: "12px",
              }}
              onClick={() => {
                handleViewActivityDetails(item);
              }}
            >
              {item?.id}
            </div>
          ),
          id: (
            <div
              onClick={() => {
                handleRouteToServiceBooking(item?.service_booking?.id);
              }}
              style={{
                fontWeight: "600",
                fontSize: "12px",
                textAlign: "center",
                color: "rgba(233, 94, 51, 1)",
                textDecoration: "underline",
                textDecorationColor: "rgba(233, 94, 51, 0.25)",
                cursor: "pointer",
              }}
            >
              <div style={{ fontSize: "12px" }}>{item?.customer_name}</div>
              <div style={{ fontSize: "12px" }}>
                Booking Id:{item?.service_booking?.id}
              </div>
            </div>
          ),
          // customer_name: <div> </div>,

          city: <p style={{ fontSize: "12px" }}>{`${item?.city || "N.A"}`}</p>,
          date: (
            <p style={{ fontSize: "12px" }}>{`${
              helperdayMonthYearFull(item?.date) || "N.A"
            }`}</p>
          ),

          crop: <p style={{ fontSize: "12px" }}>{`${item?.crop || "N.A"}`}</p>,
          tafget_acres_today: (
            <p style={{ fontSize: "12px" }}>{`${item?.acres_done_today}/${
              item?.tafget_acres_today || "N.A"
            }`}</p>
          ),

          kml_file: (
            <div>
              <a href={item?.kml_file} target="_blank">
                <img
                  src={pdfDownload}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </a>
            </div>
          ),
          signed_log: (
            <div>
              <a href={item?.signed_log} target="_blank">
                <img
                  src={pdfDownload}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </a>
            </div>
          ),

          action: (
            <div className={styles.languageCon}>
              {user_permissions?.update && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleEditclick(item)}
                >
                  <img src={editIcon} />
                </div>
              )}
              {user_permissions?.delete && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpenDeleteModal(item?.id)}
                >
                  <img src={delete_icon} />
                </div>
              )}
            </div>
          ),
        };
      });

      setActivityReportList([...modifiedArray]);
    } catch (error) {
      console.log("FFFFFFsSS", error);
    }
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      let filterObject = {};

      if (pageState) {
        filterObject.page = pageState;
      }
      if (filters?.q) {
        filterObject.q = filters?.q;
      }
      if (filters?.activity_id) {
        filterObject.activity_id = filters?.activity_id;
      }
      if (filters?.city) {
        filterObject.city = filters?.city;
      }
      if (filters?.crop) {
        filterObject.crop = filters?.crop;
      }
      if (filters?.date) {
        filterObject.date = formatDate(filters?.date);
      }

      if (filters?.service_booking) {
        const ids = filters?.service_booking?.map((info) => info?.id);
        filterObject.service_booking = ids[0];
      }

      getActivityReportsListing(filterObject);
    }, 500);

    return () => clearTimeout(timeOut);
  }, [
    pageState,
    filters?.service_booking,
    filters?.q,
    filters?.activity_id,
    filters?.city,
    filters?.crop,
    filters?.date,
  ]);

  const handleAddnewActivity = () => {
    setOpenDrawer(true);
    setDrawerType("addActivity");
    setUpdateId(null);
    setFormErrors({});
    setFormData((prev) => ({
      ...prev,
      acres_done_today: "",
      city: "",
      country: "",
      crop: "",
      customer_name: "",
      customer_email: "",
      customer_phone: "",
      date: dayjs(),
      district: "",
      line_1: "",
      line_2: "",
      liquid_used: "",
      pincode: "",
      plot_no: "",
      remark: "",
      spray_start_time: "",
      spray_end_time: "",
      state: "",
      tafget_acres_today: "",
    }));
    setPilotSelected([]);
    setServiceBookingSelected([]);
    setFarmPhotoFiles([]);
    setFarmPhotoPreviews([]);
    setSignedFile("");
    setSignedFilePreview("");
    // updLoc({ lat: 20.5937, lng: 78.9629 });

    // setFormData({});
  };

  const handleEditclick = (item) => {
    setOpenDrawer(true);
    setDrawerType("addActivity");
    setFormErrors({});
    setUpdateId(item?.id);
    updLoc((prev) => ({
      ...prev,
      lat: item?.service_booking?.lat,
      lng: item?.service_booking?.long,
    }));
    setFormData((prev) => ({
      ...prev,
      acres_done_today: item?.acres_done_today,
      city: item?.city,
      country: item?.country,
      crop: item?.crop,
      customer_name: item?.customer_name,
      customer_email: item?.customer_email,
      customer_phone: item?.customer_phone,
      date: dayjs(item?.date),
      district: item?.district,
      line_1: item?.line_1,
      line_2: item?.line_2,
      liquid_used: item?.liquid_used,
      pincode: item?.pincode,
      plot_no: item?.plot_no,
      remark: item?.remark,
      spray_start_time: item?.spray_start_time,
      spray_end_time: item?.spray_end_time,
      state: item?.state,
      tafget_acres_today: item?.tafget_acres_today,
    }));
    setPilotSelected([item?.pilot_details]);
    setServiceBookingSelected([item?.service_booking]);

    setKmlFilePreview(item?.kml_file);
    setSignedFilePreview(item?.signed_log);
    setFarmPhotoPreviews(item?.activitymedia);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "pincode") {
      if (value.length > 6) {
        return;
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeSingle = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && !multiple) {
      setFormData({
        ...formData,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    // const { name, target } = e.target;
  };

  const handleFilter = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setfilters({
        ...filters,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setfilters({
        ...filters,
        [selectName]: [e],
      });
    }
  };

  const handleDeleteChip = (id, selectName) => {
    setfilters({
      ...filters,
      [selectName]: filters?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  async function farmPhotosUpload(activityId) {
    if (!updateId) {
      if (farmPhotoFiles && farmPhotoFiles.length > 0) {
        const allBanners = [...farmPhotoFiles];

        let formData = new FormData();
        allBanners.forEach((banner) => {
          formData.append("images", banner);
        });
        formData.append("activity", activityId);

        try {
          const addBanners = await axios({
            method: "post",
            url: `${base_url}service/activity/media/`,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${access_token}`,
            },
          });

          if (addBanners.status === 201) {
            alert("Farm Images Uploaded Successfully!");
            // getHotelDetailsforUpdate();
            setFarmPhotoFiles([]);
            // alert("New Hotel successfully created!");
          }
          // Handle success
        } catch (error) {
          // Handle error
        }
      }
    } else {
      if (farmPhotoFiles && farmPhotoFiles.length > 0) {
        const allBanners = [...farmPhotoFiles];

        let formData = new FormData();
        allBanners.forEach((banner) => {
          formData.append("images", banner);
        });
        formData.append("activity", activityId);

        try {
          const addBanners = await axios({
            method: "post",
            url: `${base_url}service/activity/media/`,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${access_token}`,
            },
          });

          if (addBanners.status === 201) {
            // getHotelDetailsforUpdate();
            alert("Farm Photos Uploaded Successfully!");
            setFarmPhotoFiles([]);
            // alert("Image successfully uploaded!");
          }
          // Handle success
        } catch (error) {
          // Handle error
        }
      }
    }
  }

  const handleMediaDelete = async (type, itemIndex, id) => {
    if (type === "image") {
      if (id) {
        token_api
          .delete(`/service/activity/media/${id}/`)
          .then((response) => {
            if (response?.status == 200 || response?.status == 204) {
              alert("Farm Image Deleted Successfully");
              setOpenDrawer(false);
              getActivityReportsListing();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const mediaFilter = courseMedia.filter(
          (item, index) => index !== itemIndex
        );
        setCourseMedia(mediaFilter);
      }
    }
  };

  const handleHotelMedia = (receive) => {
    if (receive == "post") {
      farmPhotosUpload(updateId);
    }
  };

  const handleRemoveImage = (indexToRemove) => {
    setFarmPhotoPreviews((prevFarmPhotos) =>
      prevFarmPhotos.filter((_, index) => index !== indexToRemove)
    );
  };
  const handleSave = async () => {
    const data = {
      ...(pilotSelected.length > 0 && {
        pilot: pilotSelected[0]?.id,
      }),
      ...(serviceBookingSeleted.length > 0 && {
        service_booking_id: serviceBookingSeleted[0]?.id,
      }),
      acres_done_today: formData?.acres_done_today,
      city: formData?.city,
      country: formData?.country,
      crop: formData?.crop,
      customer_name: formData?.customer_name,
      customer_email: formData?.customer_email,
      customer_phone: formData?.customer_phone,
      date: formatDate(formData?.date),
      district: formData?.district,
      lat: loc?.lat,
      long: loc?.lng,
      line_1: formData?.line_1,
      line_2: formData?.line_2,
      liquid_used: formData?.liquid_used,
      pincode: formData?.pincode,
      plot_no: formData?.plot_no,
      remark: formData?.remark,
      state: formData?.state,

      spray_start_time: formData?.spray_start_time,
      spray_end_time: formData?.spray_end_time,
      tafget_acres_today: formData?.tafget_acres_today,
    };

    try {
      Validate(formData, pilotSelected, serviceBookingSeleted)
        .then(async (response) => {
          if (response === "success") {
            if (updateId) {
              const response = await token_api.patch(
                `service/pilot/activity_report/${updateId}/`,
                data
              );
              if (kmlFile || signedFile) {
                let activityMedia = new FormData();
                // activityMedia.append("kml_file", kmlFile);
                activityMedia.append("signed_log", signedFile);

                await axios({
                  method: "patch",
                  url: `${base_url}service/pilot/activity_report/${response?.data?.data?.id}/`,
                  data: activityMedia,
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${access_token}`,
                  },
                });
              }

              // if (farmPhotoFiles && farmPhotoFiles.length > 0) {
              //   const farmImages = [...farmPhotoFiles];
              //   let farmMedia = new FormData();
              //   // activityMedia.append("kml_file", kmlFile);
              //   farmImages.forEach((image) => {
              //     farmMedia.append("farm_img", image);
              //   });

              //   await axios({
              //     method: "patch",
              //     url: `${base_url}service/pilot/activity_report/${response?.data?.data?.id}/`,
              //     data: farmMedia,
              //     headers: {
              //       "Content-Type": "multipart/form-data",
              //       Authorization: `Bearer ${access_token}`,
              //     },
              //   });
              // }

              let imageUpload = await farmPhotosUpload(updateId);

              alert("Activity Details Updated Successfully!");

              setFormErrors({});
              setKmlFile("");
              setSignedFile("");
              setOpenDrawer(false);
              getActivityReportsListing();

              setFormData({});
              setPilotSelected([]);
              setServiceBookingSelected([]);
            } else {
              const responseData = await token_api.post(
                `service/pilot/activity_report/`,
                data
              );
              if (responseData?.status !== 201) {
                throw new Error("Activity not created");
              }

              if (kmlFile || signedFile) {
                let activityMedia = new FormData();
                // activityMedia.append("kml_file", kmlFile);
                activityMedia.append("signed_log", signedFile);

                await axios({
                  method: "patch",
                  url: `${base_url}service/pilot/activity_report/${responseData?.data?.data?.id}/`,
                  data: activityMedia,
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${access_token}`,
                  },
                });
              }

              let imageUpload = await farmPhotosUpload(
                responseData?.data?.data?.id
              );

              alert("New Activity Created Successfully!");
              setFormErrors({});
              setKmlFile("");
              setSignedFile("");
              setOpenDrawer(false);
              getActivityReportsListing();

              setFormData({});
              setPilotSelected([]);
              setServiceBookingSelected([]);
            }
          }
        })
        .catch((error) => {
          setFormErrors(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (formData?.pincode && formData?.pincode?.length === 6) {
        try {
          setLoading(true);

          const response = await axios.get(
            `https://api.postalpincode.in/pincode/${formData?.pincode}`
          );

          if (
            response.data.length !== 0 &&
            response.data[0].PostOffice.length !== 0
          ) {
            const pinData = response.data[0].PostOffice[0];
            const { District, Division, State, Name, Region, Country } =
              pinData;

            setFormData({
              ...formData,
              state: State,
              district: District,
              // city: District,
              country: Country,
            });

            // setFormData({
            //   ...formData,
            //   district: Name,
            //   city: Region,
            //   state: State,
            //   country: Country,
            //   pincode: pin,
            // });
            // setShowOther(true);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [formData.pincode]);

  const handleAutocompleteChangeforPilot = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Pilot already included");
    } else {
      setPilotSelected(selectedValues);
    }
  };

  const handleAutocompleteChangeforServiceBooking = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Service already included");
    } else {
      setServiceBookingSelected(selectedValues);
    }
  };

  const handleAutocompleteChangeforServiceBookingFilter = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Service booking already included");
    } else {
      setfilters({
        ...filters,
        ["service_booking"]: selectedValues,
      });
    }
  };

  //Export logic...

  const handleCloseModal = () => {
    setExportData({
      start_date: null,
      end_date: null,
    });
    setOpenPopUp(false);
  };
  const handleExportData = async () => {
    try {
      if (!exportData?.start_date || !exportData?.end_date) {
        alert("Please select start date and end dates.");
      } else {
        const response = await axios.get(
          `${base_url}service/pilot/activity_report_export?from_date=${formatDate(
            exportData?.start_date
          )}&to_date=${formatDate(exportData?.end_date)}`
        );
        const excelUrl = response.data.data.data.excel_url;
        const link = document.createElement("a");
        link.href = excelUrl;
        link.setAttribute("download", `${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();

        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Activity Report file downloaded successfully!");
          setExportData({
            start_date: null,
            end_date: null,
          });
          setOpenPopUp(false);
          getActivityReportsListing();
        }
      }
    } catch (error) {
      console.error("Error exporting the file", error);
    }
  };

  return (
    <Container style={{ overflowX: "auto" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "30px 0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        </div>
        {user_permissions?.write && (
          <Button
            variant="contained"
            className="btnLinearGradient"
            onClick={handleAddnewActivity}
          >
            + New Activity Report{" "}
          </Button>
        )}
      </div>

      <div className={styles.cardsContainer}>
        <div
          className={styles.cardItem}
          style={{
            background:
              "linear-gradient(to left, rgb(81, 79, 255), rgb(87, 194, 255))",
          }}
        >
          <h3 style={{ fontSize: "16px" }}> Total Acres Booked</h3>
          <p style={{ fontSize: "14px" }}>
            {dashoboardData?.total_acres_booked}
          </p>
        </div>
        <div
          className={styles.cardItem}
          style={{
            background:
              "linear-gradient(to left, rgb(132, 98, 251), rgb(235, 137, 235))",
          }}
        >
          <h3 style={{ fontSize: "16px" }}> Total Target Acres Today</h3>
          <p style={{ fontSize: "14px" }}>
            {dashoboardData?.total_target_acres_today}
          </p>
        </div>{" "}
        <div
          className={styles.cardItem}
          style={{
            background:
              "linear-gradient(to right, rgba(160, 181, 224, 0.77), rgb(124, 147, 195))",
          }}
        >
          <h3 style={{ fontSize: "16px" }}> Total Acres Done Today</h3>
          <p style={{ fontSize: "14px" }}>
            {dashoboardData?.total_acres_done_today}
          </p>
        </div>{" "}
        <div
          className={styles.cardItem}
          style={{
            background:
              "linear-gradient(to right, rgb(87, 182, 122), rgb(11, 119, 84))",
          }}
        >
          <h3 style={{ fontSize: "16px" }}> Overall Acres Done</h3>
          <p style={{ fontSize: "14px" }}>
            {dashoboardData?.overall_acres_done}
          </p>
        </div>{" "}
        <div
          className={styles.cardItem}
          style={{
            background:
              "linear-gradient(to left, rgb(246, 133, 100), rgb(245, 199, 99))",
          }}
        >
          <h3 style={{ fontSize: "16px" }}> Overall Acres Remaining</h3>
          <p style={{ fontSize: "14px" }}>
            {dashoboardData?.overall_acres_remaining}
          </p>
        </div>
      </div>

      <FilterContainer
        className={styles.filterContainer}
        // className={`${styles.marginTopBox}`}
      >
        <CustomSearchFilter
          placeholder="Search by activity id, city, crop..."
          onChange={(e) => {
            setfilters({ ...filters, q: e });
          }}
        />

        {/* <InputFields
          label="Filter By Activity ID"
          name="activity_id"
          type="number"
          value={filters?.activity_id}
          onChange={(e) => {
            setfilters({ ...filters, activity_id: e.target.value });
          }}
        /> */}
        {/* <InputFields
          label="Filter By City"
          name="city"
          value={filters?.city}
          onChange={(e) => {
            setfilters({ ...filters, city: e.target.value });
          }}
        /> */}

        {/* <InputFields
          label="Filter By Crop"
          name="crop"
          value={filters?.crop}
          onChange={(e) => {
            setfilters({ ...filters, crop: e.target.value });
          }}
        /> */}

        {/* <CustomDatePicker
          label="Date"
          value={filters?.date}
          onChange={(newValue) =>
            setfilters({
              ...filters,
              date: newValue,
            })
          }
        /> */}

        {/* <CustomSelectChip
          multiple={false}
          label="Filter by service booking "
          name="service_booking"
          listArray={serviceBookingList}
          onChange={handleFilter}
          onDelete={handleDeleteChip}
          value={filters?.service_booking}
        /> */}

        <DroneServiceBooking
          value={filters?.service_booking}
          onChange={handleAutocompleteChangeforServiceBookingFilter}
        />
        {/* 
        <CustomButton
          text={"ADVANCE FILTERS"}
          btnIcon={<img src={filterIcon} alt="adv-filter" />}
          // maxWidth="170px"
        /> */}
        <CustomButton
          text={"Export"}
          maxWidth="110px"
          onClick={() => {
            setOpenPopUp(true);
          }}
        />
      </FilterContainer>
      <div className={styles.tableContainer}>
        <CustomTable
          columns={tableColumns}
          data={activityReportList}
          // handleClick={handleRedirect}
          tableFor="systemUsers"
        />
      </div>
      <div className={styles.paginationContainer}>
        <CustomPagination max_pages={max_pages} setPageState={setPageState} />
      </div>

      <DrawerComp
        open={openDrawer}
        onClose={() =>
          setOpenDrawer(
            (prev) => !prev,
            setFarmPhotoPreviews([]),
            setSignedFilePreview("")
          )
        }
        anchor="right"
      >
        {drawerType === "addActivity" && (
          <DrawerContainer>
            <DrawerTitle
              text={updateId ? "Update Activity" : "Add Activity"}
              handleCancelDrw={() =>
                setOpenDrawer(
                  (prev) => !prev,
                  setFarmPhotoPreviews([]),
                  setSignedFilePreview("")
                )
              }
            />

            <div style={{ display: "flex", justifyContent: "center" }}>
              <MyMap
                width="30vw"
                height="40vh"
                search={formData?.pincode}
                loc={loc}
                updLoc={updLoc}
              />
            </div>

            <div style={{ marginTop: "10px" }}>
              <DroneServiceBooking
                value={serviceBookingSeleted}
                onChange={handleAutocompleteChangeforServiceBooking}
              />
              {formErrors?.serviceBookingSeleted && (
                <p style={{ color: "red" }}>
                  {formErrors?.serviceBookingSeleted}
                </p>
              )}
            </div>

            <div>
              <div style={{ marginTop: "10px" }}>
                <InputFields
                  label="Pincode"
                  name="pincode"
                  value={formData?.pincode || ""}
                  onChange={handleChange}
                  error={formErrors?.pincode}
                  helperText={formErrors?.pincode}
                  maxLength={6}
                  type="number"
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <InputFields
                  label="Customer Name"
                  // required
                  name="customer_name"
                  value={formData?.customer_name || ""}
                  onChange={handleChange}
                  error={formErrors?.customer_name}
                  helperText={formErrors?.customer_name}
                />
              </div>

              <div style={{ marginTop: "10px" }}>
                <InputFields
                  label="Customer Email"
                  // required
                  name="customer_email"
                  value={formData?.customer_email || ""}
                  onChange={handleChange}
                  error={formErrors?.customer_email}
                  helperText={formErrors?.customer_email}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <InputFields
                  label="Customer Mobile"
                  // required
                  name="customer_phone"
                  value={formData?.customer_phone || ""}
                  onChange={handleChange}
                  error={formErrors?.customer_phone}
                  helperText={formErrors?.customer_phone}
                />
              </div>

              <div style={{ marginTop: "10px" }}>
                <InputFields
                  label="City"
                  // required
                  name="city"
                  value={formData?.city || ""}
                  onChange={handleChange}
                  error={formErrors?.city}
                  helperText={formErrors?.city}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <InputFields
                  label="Plot No."
                  // required
                  // type="number"
                  name="plot_no"
                  value={formData?.plot_no || ""}
                  onChange={handleChange}
                  error={formErrors?.plot_no}
                  helperText={formErrors?.plot_no}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <InputFields
                  label="Line 1"
                  // required
                  name="line_1"
                  value={formData?.line_1 || ""}
                  onChange={handleChange}
                  error={formErrors?.line_1}
                  helperText={formErrors?.line_1}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <InputFields
                  label="Line 2"
                  // required
                  name="line_2"
                  value={formData?.line_2 || ""}
                  onChange={handleChange}
                  error={formErrors?.line_2}
                  helperText={formErrors?.line_2}
                />
              </div>

              <div style={{ marginTop: "10px" }}>
                <InputFields
                  label="District"
                  // required
                  name="district"
                  value={formData?.district || ""}
                  onChange={handleChange}
                  error={formErrors?.district}
                  helperText={formErrors?.district}
                />
              </div>

              <div style={{ marginTop: "10px" }}>
                <InputFields
                  label="State"
                  // required
                  name="state"
                  value={formData?.state || ""}
                  onChange={handleChange}
                  error={formErrors?.state}
                  helperText={formErrors?.state}
                />
              </div>

              <div style={{ marginTop: "10px" }}>
                <InputFields
                  label="Country"
                  // required
                  name="country"
                  value={formData?.country || ""}
                  onChange={handleChange}
                  error={formErrors?.country}
                  helperText={formErrors?.country}
                />
              </div>

              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <div style={{ width: "90%" }}>
                  <CustomDatePicker
                    label="Date"
                    value={formData?.date}
                    onChange={(newValue) =>
                      setFormData({
                        ...formData,
                        date: newValue,
                      })
                    }
                    error={formErrors?.date}
                  />
                </div>
              </div>

              <div style={{ marginTop: "10px" }}>
                <DurationPicker
                  style={{ width: "100%" }}
                  label="Spray Start Time"
                  size="small"
                  name="spray_start_time"
                  onChange={handleChange}
                  value={formData?.spray_start_time}
                  error={formErrors?.spray_start_time}
                  // required
                  // error={startTimeCheck}
                  helperText={
                    formErrors?.spray_start_time ? "Enter spray start time" : ""
                  }
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <DurationPicker
                  style={{ width: "100%" }}
                  label="Spray End Time"
                  size="small"
                  name="spray_end_time"
                  onChange={handleChange}
                  value={formData?.spray_end_time}
                  error={formErrors?.spray_end_time}
                  // required
                  // error={startTimeCheck}
                  helperText={
                    formErrors?.spray_end_time ? "Enter spray end time" : ""
                  }
                />
              </div>

              <div style={{ marginTop: "10px" }}>
                <InputFields
                  label="Crop"
                  name="crop"
                  value={formData?.crop || ""}
                  onChange={handleChange}
                  error={formErrors?.crop}
                  helperText={formErrors?.crop}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <InputFields
                  label="Liquid Used"
                  name="liquid_used"
                  value={formData?.liquid_used || ""}
                  onChange={handleChange}
                  error={formErrors?.liquid_used}
                  helperText={formErrors?.liquid_used}
                />
              </div>

              <div style={{ marginTop: "10px" }}>
                <InputFields
                  label="Acres Done Today"
                  name="acres_done_today"
                  type="number"
                  value={formData?.acres_done_today || ""}
                  onChange={handleChange}
                  error={formErrors?.acres_done_today}
                  helperText={formErrors?.acres_done_today}
                />
              </div>

              <div style={{ marginTop: "10px" }}>
                <InputFields
                  label="Target Acres Day"
                  name="tafget_acres_today"
                  type="number"
                  value={formData?.tafget_acres_today || ""}
                  onChange={handleChange}
                  error={formErrors?.tafget_acres_today}
                  helperText={formErrors?.tafget_acres_today}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <textarea
                  style={{
                    width: "100%",
                    padding: "20px",
                    boxSizing: "border-box",
                  }}
                  placeholder="Remarks...."
                  rows={10}
                  label="Remarks"
                  name="remark"
                  value={formData?.remark || ""}
                  onChange={handleChange}
                  error={formErrors?.remark}
                  helperText={formErrors?.remark}
                />
                {formErrors?.remark && (
                  <p style={{ color: "red" }}>Please Enter Remarks....</p>
                )}
              </div>

              <div style={{ marginTop: "10px" }}>
                <DronePilot
                  value={pilotSelected}
                  onChange={handleAutocompleteChangeforPilot}
                />
                {formErrors?.pilotSelected && (
                  <p style={{ color: "red" }}>{formErrors?.pilotSelected}</p>
                )}
              </div>

              {/* <div className={`${styles.formContainer} mt-20`}>
                <div
                  style={{ color: palette?.primary[500] }}
                  className={`${styles.titleStyles}`}
                >
                  Kml File
                </div>
  
                <div style={{ marginTop: "20px" }}>
                  <div
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, "kml_file")}
                    className={styles.dropBoxStyles}
                  >
                    <div
                      onClick={() => handleClick("kml_file")}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p>
                        <img src={dragDrop} alt="dradanddrop" />
                      </p>
                      <p>Drag & Drop the File or click here</p>
                      <input
                        ref={kmlInput}
                        type="file"
                        // id="myfile"
                        // name="myfile"
                        // multiple
                        onChange={(e) => handleDrop(e, "kml_file")}
                        style={{ display: "none" }}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
              {kmlFilePreview && (
                <div className={`${styles.formContainer} mt-20`}>
                  <div className={styles.commonFieldsBox}>
                    <div>
                      <p
                        style={{ color: palette?.primary[500] }}
                        className={`${styles.titleStyles}`}
                      >
                        Kml File Preview
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div
                          className={`${styles.flexBetween} ${styles.imgCardBox} `}
                        >
                          {" "}
                          <img
                            src={kmlFilePreview}
                            alt="kml_file"
                            width={200}
                            height={140}
                          />
                          <div onClick={() => handleClick("kml_file")}>
                            <Button
                              variant="contained"
                              // onClick={() => {
                              //   handleMediaDelete("image");
                              // }}
                            >
                              Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}

              <div className={`${styles.formContainer} mt-20`}>
                <div
                  style={{ color: palette?.primary[500] }}
                  className={`${styles.titleStyles}`}
                >
                  SignedLog File Preview
                </div>

                <div style={{ marginTop: "20px" }}>
                  <div
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, "signed_log")}
                    className={styles.dropBoxStyles}
                  >
                    <div
                      onClick={() => handleClick("signed_log")}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p>
                        <img src={dragDrop} alt="dradanddrop" />
                      </p>
                      <p>Drag & Drop the File or click here</p>
                      <input
                        ref={signedInput}
                        type="file"
                        // id="myfile"
                        // name="myfile"
                        // multiple
                        onChange={(e) => handleDrop(e, "signed_log")}
                        style={{ display: "none" }}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
              {signedFilePreview && (
                <div className={`${styles.formContainer} mt-20`}>
                  <div className={styles.commonFieldsBox}>
                    <div>
                      <p
                        style={{ color: palette?.primary[500] }}
                        className={`${styles.titleStyles}`}
                      >
                        SignedLog File Preview
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div
                          className={`${styles.flexBetween} ${styles.imgCardBox} `}
                        >
                          {" "}
                          <img
                            src={signedFilePreview}
                            alt="signed_log"
                            width={200}
                            height={140}
                          />
                          <div onClick={() => handleClick("signed_log")}>
                            <Button
                              variant="contained"
                              // onClick={() => {
                              //   handleMediaDelete("image");
                              // }}
                            >
                              Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className={`${styles.formContainer} mt-20`}>
                <div
                  style={{ color: palette?.primary[500] }}
                  className={`${styles.titleStyles}`}
                >
                  Farm Photo{" "}
                </div>

                <div style={{ marginTop: "20px" }}>
                  <div
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, "farm_photos")}
                    className={styles.dropBoxStyles}
                  >
                    <div
                      onClick={() => handleClick("farm_photos")}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p>
                        <img src={dragDrop} alt="dradanddrop" />
                      </p>
                      <p>Drag & Drop the File or click here</p>
                      <input
                        ref={farmPhotoInput}
                        type="file"
                        // id="myfile"
                        // name="myfile"
                        multiple
                        onChange={(e) => handleDrop(e, "farm_photos")}
                        style={{ display: "none" }}
                      ></input>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "10px" }}>
                  {updateId && (
                    <div>
                      <Button
                        variant="contained"
                        onClick={() => handleHotelMedia("post")}
                      >
                        Upload
                      </Button>
                    </div>
                  )}
                </div>
              </div>

              {farmPhotosPreviews?.map((banner, index) => (
                <div
                  key={banner?.id}
                  className={`${styles.flexBetween} ${styles.imgCardBox} `}
                  style={{ gap: "10px", marginTop: "10px" }}
                >
                  <div style={{ width: "200px" }}>
                    <img
                      // style={{ width: "100px", height: "100px" }}
                      // width={200}
                      // height={140}
                      style={{ width: "100%" }}
                      src={banner?.image}
                      alt={`Uploaded image ${index}`}
                    />
                  </div>

                  <div>
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleMediaDelete("image", index, banner?.id);
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              ))}
              {farmPhotoFiles.map((banner, index) => (
                <div
                  key={index}
                  className={`${styles.flexBetween} ${styles.imgCardBox} `}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <div>
                    <img
                      width={200}
                      height={140}
                      src={URL.createObjectURL(banner)}
                      alt={`Uploaded image ${index}`}
                    />
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      onClick={() => handleRemoveImage(index)}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              ))}
            </div>

            <div style={{ marginTop: "10px" }}>
              <Button variant="contained" onClick={handleSave}>
                Save
              </Button>
            </div>
          </DrawerContainer>
        )}
        {drawerType === "viewActivity" && (
          <DrawerContainer>
            <DrawerTitle
              text={`Service ID # ${activityView?.id}`}
              handleCancelDrw={() => setOpenDrawer((prev) => !prev)}
            />
            <div className={styles.customerCon}>
              <img
                src={
                  serviceBookingDetails?.user_detail?.avatar
                    ? serviceBookingDetails?.user_detail?.avatar
                    : serviceBookingDetails?.user_detail?.default_avatar
                }
              />
              <h3>{activityView?.customer_name}</h3>
            </div>
            <div className={styles.serviceLocationCon}>
              <h3>Service Location Address</h3>
              <p>{`${serviceBookingDetails?.plot_no} ,${serviceBookingDetails?.city}, ${serviceBookingDetails?.district}, ${serviceBookingDetails?.state}, ${serviceBookingDetails?.country}, ${serviceBookingDetails?.pincode}`}</p>
            </div>
            <div className={styles.mapDataCon}>
              <div className={styles.mapCon}>
                <MyMap
                  width="30vw"
                  height="40vh"
                  search={activityView?.pincode}
                  loc={loc}
                  updLoc={updLoc}
                />
              </div>
              <div>
                <p>Location of Pilot When Activity Report Was Submitted</p>
                <h4>{activityView?.pilot_details?.location}</h4>

                <div className={styles.createdCon}>
                  <div>
                    <h3>Pilot Name</h3>
                    <p>{`${activityView?.pilot_details?.first_name} ${activityView?.pilot_details?.last_name}`}</p>
                  </div>
                </div>
                <div className={styles.createdCon}>
                  <div>
                    <h3>Created By</h3>
                    {/* <p>{serviceBookingDetails?.created_by || "N.A"}</p> */}
                    <p>
                      {formatDate(serviceBookingDetails?.created)},{" "}
                      {extractDateTime(serviceBookingDetails?.created)}
                    </p>
                  </div>
                  <div>
                    <h3>Modified By</h3>
                    {/* <p>{serviceBookingDetails?.modified_by || "N.A"}</p> */}
                    <p>
                      {formatDate(serviceBookingDetails?.modified)},
                      {extractDateTime(serviceBookingDetails?.created)}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.wrapCon}>
              <div className={styles.itemCon}>
                <h4>Name of the Farmer</h4>
                <p>{activityView?.customer_name}</p>
                <p>{activityView?.customer_phone}</p>
              </div>
              <div className={styles.itemCon}>
                <h4>Crop Name</h4>
                <p>{activityView?.crop}</p>
              </div>{" "}
              <div className={styles.itemCon}>
                <h4>Acre Done Today</h4>
                <p>{activityView?.acres_done_today}</p>
              </div>{" "}
              <div className={styles.itemCon}>
                <h4>Target Acre Today</h4>
                <p>{activityView?.tafget_acres_today}</p>
              </div>{" "}
              <div className={styles.itemCon}>
                <h4>Spray Start Time</h4>
                <p>{convertTo12HourFormat(activityView?.spray_start_time)}</p>
              </div>{" "}
              <div className={styles.itemCon}>
                <h4>Spray End Time</h4>
                {activityView && activityView?.spray_end_time ? (
                  <p>{convertTo12HourFormat(activityView?.spray_end_time)}</p>
                ) : (
                  "N.A"
                )}
              </div>
              {activityView && activityView?.date && (
                <div className={styles.itemCon}>
                  <h4>Date of Spraying</h4>

                  <p>{helperdayMonthYearFull(activityView?.date)}</p>
                </div>
              )}
              {serviceBookingDetails &&
                serviceBookingDetails?.bookingsservicedrone &&
                serviceBookingDetails?.bookingsservicedrone.length > 0 && (
                  <div className={styles.itemCon}>
                    <h4>Drone Details</h4>
                    <p>
                      {serviceBookingDetails?.bookingsservicedrone[0]
                        ?.drone_inventory[0]?.serial_no || "N.A"}
                    </p>
                    <p>
                      {
                        serviceBookingDetails?.bookingsservicedrone[0]
                          ?.drone_inventory[0]?.drone?.title
                      }
                    </p>
                  </div>
                )}
              {serviceBookingDetails &&
                serviceBookingDetails?.bookingsservicedrone &&
                serviceBookingDetails?.bookingsservicedrone.length > 0 && (
                  <div className={styles.itemCon}>
                    <h4>Drone Status</h4>
                    <p>
                      {serviceBookingDetails?.bookingsservicedrone[0]
                        ?.drone_inventory[0]?.status_str || "N.A"}
                    </p>
                  </div>
                )}
            </div>

            {serviceBookingDetails &&
              serviceBookingDetails?.bookingserviceproduct &&
              serviceBookingDetails?.bookingserviceproduct?.length > 0 && (
                <div>
                  <h3 style={{ color: "rgba(254, 154, 10, 1)" }}>Products</h3>
                  <div className={styles.productsList}>
                    {serviceBookingDetails?.bookingserviceproduct.map(
                      (item, index) => {
                        return (
                          <div key={index} className={styles.productItem}>
                            <img
                              src={item?.product?.thumbnail}
                              style={{ width: "80px", height: "80px" }}
                            />
                            <p>{item?.product?.product_name}</p>
                            <p>{item?.quantity} Qty</p>
                            <p>Rs. {item?.amount}</p>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              )}

            {activityView?.activitymedia &&
              activityView?.activitymedia.length > 0 && (
                <div>
                  <h3 style={{ color: "rgba(254, 154, 10, 1)" }}>
                    Farm Photos
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "20px",
                      flexWrap: "wrap",
                      margin: "20px 0px",
                    }}
                  >
                    {activityView?.activitymedia.map((item, index) => {
                      return (
                        <img
                          key={index}
                          src={item?.image}
                          style={{ width: "200px", height: "200px" }}
                        />
                      );
                    })}

                    {/* <img src={farmImage} />
  <img src={farmImage} /> */}
                  </div>
                </div>
              )}

            <div className={styles.signedCon}>
              <p>Signed log from copy (Signed by Farmer)</p>
              <a href={activityView?.signed_log} target="_blank">
                <img
                  src={blackpdfDownload}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </a>
            </div>
          </DrawerContainer>
        )}
      </DrawerComp>

      <CustomModal open={openPopUp} onClose={handleCloseModal}>
        <div>
          <h3 style={{ marginBottom: "10px", color: "red" }}>
            Please select start date and end date.
          </h3>
          <div style={{ marginTop: "20px" }}>
            <CustomDatePicker
              label="Start Date"
              value={exportData?.start_date}
              onChange={(newValue) =>
                setExportData({
                  ...exportData,
                  start_date: newValue,
                })
              }
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <CustomDatePicker
              label="End Date"
              value={exportData?.end_date}
              onChange={(newValue) =>
                setExportData({
                  ...exportData,
                  end_date: newValue,
                })
              }
            />
          </div>{" "}
          <Button
            variant="contained"
            onClick={handleExportData}
            style={{ marginTop: "20px" }}
          >
            Download
          </Button>
        </div>
      </CustomModal>
    </Container>
  );
};
export default ActivityReportList;

const tableColumns = [
  {
    id: "activityId",
    label: "Activity ID",
  },
  {
    id: "id",
    label: "Service Booking",
  },
  {
    id: "city",
    label: "City",
  },

  {
    id: "date",
    label: "Date",
  },
  {
    id: "crop",
    label: "Crop",
  },

  {
    id: "tafget_acres_today",
    label: "Target Acre Today",
  },
  // {
  //   id: "kml_file",
  //   label: "KML File",
  // },
  {
    id: "signed_log",
    label: "Signed File",
  },
  {
    id: "action",
    label: "Action",
  },
];
