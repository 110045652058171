import React, { useContext, useEffect, useState } from "react";
import styles from "./Coupons.module.css";
import Container from "../../Component/Container/Container";
import CustomTable from "../../Component/CustomTable/CustomTable";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import { Link, useNavigate } from "react-router-dom";
import { Button, useTheme, Checkbox, colors } from "@mui/material";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import { token_api } from "../../Utils/network";
import editIcon from "../../Assets/edit_icon.svg";
import deleteIcon from "../../Assets/delete_icon.svg";
import couponIcon from "../../Assets/couponIcon.svg";
import couponAvailable from "../../Assets/droneAvailable.svg";
import couponNotAvailable from "../../Assets/droneNotAvailable.svg";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import { formatDate, extractDateTime } from "../../Utils/helper";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";

const Coupons = () => {
  const { user_permissions } = useContext(UserCredsContext);
  const navigate = useNavigate();
  const [couponList, setCouponList] = useState([]);
  const [couponDetails, setCouponDetails] = useState({});
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [filters, setfilters] = useState({});
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Coupons</div>,
  ];

  const handleAddCoupon = () => {
    navigate(`/coupons/create-coupon`);
  };

  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };

  const handleOpenDrawer = (item) => {
    setCouponDetails({
      ...couponDetails,
      ...item,
    });
    setOpenDrawer(true);
  };

  const handleEditCoupon = (item) => {
    navigate(`/coupons/edit-coupon/${item?.id}`, { state: { item } });
  };
  const handleDeleteCoupon = (couponId) => {
    token_api
      .delete(`course/coupon/${couponId}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Coupon Deleted Successfully!");
          getCouponsList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCouponsList = (filters) => {
    token_api
      .get(`course/coupons`, { params: { ...filters } })
      .then((res) => {
        if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
          setPaginationData(res?.data);
          let modifiedArray = res?.data?.data?.map((item) => {
            return {
              id: (
                <div
                  // onClick={() => handleRedirect(item?.slug)}
                  style={{ fontSize: "12px" }}
                >
                  {item?.id}
                </div>
              ),
              coupon_image: (
                <div>
                  <img
                    src={item?.icon ? item?.icon : couponIcon}
                    style={{ width: "80px", height: "80px" }}
                  />
                </div>
              ),

              title: (
                <p
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    handleOpenDrawer(item);
                  }}
                >
                  {item?.title ? item?.title : "N.A"}
                </p>
              ),
              code: (
                <p style={{ fontSize: "12px" }}>
                  {item?.code ? item?.code : "N.A"}
                </p>
              ),

              discount: (
                <p style={{ fontSize: "12px" }}>
                  {item?.discount ? item?.discount : "N.A"}
                </p>
              ),
              discount_type: (
                <p style={{ fontSize: "12px" }}>{item?.discount_type_str}</p>
              ),

              status: (
                <div>
                  <img
                    src={item?.status ? couponAvailable : couponNotAvailable}
                  />
                </div>
              ),

              action: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "10px",
                    justifyContent: "center",
                  }}
                >
                  {user_permissions?.update && (
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => handleEditCoupon(item)}
                      src={editIcon}
                      // {/* Edit */}
                    />
                  )}
                  {user_permissions?.delete && (
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDeleteCoupon(item?.id)}
                      src={deleteIcon}
                      // Delete
                    />
                  )}
                </div>
              ),
            };
          });

          setCouponList([...modifiedArray]);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    const timout = setTimeout(() => {
      const filterObject = {};
      if (pageState) {
        filterObject.page = pageState;
      }
      if (filters?.q) {
        filterObject.q = filters?.q;
      }
      if (filters?.status) {
        filterObject.status = filters?.status[0]?.id;
      }

      if (filters?.discount_type) {
        filterObject.discount_type = filters?.discount_type[0]?.id;
      }

      getCouponsList(filterObject);
    }, 500);
    return () => clearTimeout(timout);
  }, [pageState, filters?.q, filters?.status, filters?.discount_type]);

  const handleDeleteChip = (id, selectName) => {
    setfilters({
      ...filters,
      [selectName]: filters?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  const handleFilter = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setfilters({
        ...filters,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setfilters({
        ...filters,
        [selectName]: [e],
      });
    }
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "30px 0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>{" "}
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        </div>

        {user_permissions?.write && (
          <Button
            onClick={handleAddCoupon}
            variant="contained"
            className={`${styles.gradientColor} ${styles.AddButton}`}
          >
            + ADD COUPON
          </Button>
        )}
      </div>

      <FilterContainer
        style={{ maxHeight: "40px", marginTop: "20px" }}
        // className={`${styles.marginTopBox}`}
      >
        <CustomSearchFilter
          placeholder="Search by ID, Title, Code..."
          onChange={(e) => {
            setPageState(1);
            setfilters({ ...filters, q: e });
          }}
        />

        <CustomSelectChip
          multiple={false}
          label="Filter By Discount Type"
          name="discount_type"
          onChange={handleFilter}
          onDelete={handleDeleteChip}
          value={filters?.discount_type}
          listArray={discountTypeList}
        />

        <CustomSelectChip
          multiple={false}
          label="Filter By Status"
          name="status"
          onChange={handleFilter}
          onDelete={handleDeleteChip}
          value={filters?.status}
          listArray={statusList}
        />
      </FilterContainer>

      <div className={styles.tableContainer}>
        <CustomTable
          columns={tableColumns}
          data={couponList}
          // handleClick={handleRedirect}
          tableFor="CouponListing"
        />
      </div>
      <div className={styles.paginationCon}>
        <CustomPagination max_pages={max_pages} setPageState={setPageState} />
      </div>

      <DrawerComp
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="right"
      >
        <DrawerContainer>
          <DrawerTitle
            text={"View Coupon Details"}
            handleCancelDrw={() => setOpenDrawer((prev) => !prev)}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "30px",
              marginTop: "30px",
            }}
          >
            <div className={styles.detailCon}>
              <h1>
                Title<span>*</span> :
              </h1>{" "}
              <p>{couponDetails?.title}</p>
            </div>
            <div className={styles.detailCon}>
              <h1>Description :</h1>{" "}
              <div
                dangerouslySetInnerHTML={{ __html: couponDetails?.description }}
              />
            </div>
            <div className={styles.detailCon}>
              <h1>
                Code <span>*</span>:
              </h1>{" "}
              <p>{couponDetails?.code}</p>
            </div>
            <div className={styles.detailCon}>
              <h1>Batch :</h1>{" "}
              <p>
                {couponDetails?.batch && !couponDetails?.all_batches
                  ? couponDetails?.batch_name
                  : "All Batches"}
              </p>
            </div>
            <div className={styles.detailCon}>
              <h1>Course :</h1>{" "}
              <p>
                {couponDetails?.course && !couponDetails?.all_courses
                  ? couponDetails?.course_name
                  : "All Courses"}
              </p>
            </div>
            <div className={styles.detailCon}>
              <h1>Service :</h1>{" "}
              <p>
                {couponDetails?.service && !couponDetails?.all_services
                  ? couponDetails?.service_name
                  : "All Services"}
              </p>
            </div>

            <div className={styles.detailCon}>
              <h1>Drone :</h1>{" "}
              <p>
                {couponDetails?.drone && !couponDetails?.all_drones
                  ? couponDetails?.drone_name
                  : "All Drones"}
              </p>
            </div>
            <div className={styles.detailCon}>
              <h1>User :</h1>{" "}
              <p>
                {couponDetails?.user && !couponDetails?.all_users
                  ? couponDetails?.user
                  : "All Users"}
              </p>
            </div>
            <div className={styles.detailCon}>
              <h1>First Time :</h1>{" "}
              <p>{couponDetails?.first_time ? "Yes" : "No"}</p>
            </div>
            <div className={styles.detailCon}>
              <h1>Multiple Time :</h1>{" "}
              <p>{couponDetails?.multiple_time ? "Yes" : "No"}</p>
            </div>
            <div className={styles.detailCon}>
              <h1>Discount Type :</h1> <p>{couponDetails?.discount_type_str}</p>
            </div>
            <div className={styles.detailCon}>
              <h1>Discount :</h1> <p>{couponDetails?.discount}</p>
            </div>
            <div className={styles.detailCon}>
              <h1>Upto :</h1> <p>{couponDetails?.upto}</p>
            </div>
            <div className={styles.detailCon}>
              <h1>Usage Upto :</h1> <p>{couponDetails?.usage_count}</p>
            </div>
            <div className={styles.detailCon}>
              <h1>From Date & Time :</h1>{" "}
              <p>
                {formatDate(couponDetails?.from_date)}{" "}
                {extractDateTime(couponDetails?.from_date)}
              </p>
            </div>
            <div className={styles.detailCon}>
              <h1>To Date & Time :</h1>{" "}
              <p>
                {formatDate(couponDetails?.to_date)}{" "}
                {extractDateTime(couponDetails?.to_date)}
              </p>
            </div>
            <div className={styles.detailCon}>
              <h1>Icon : </h1>{" "}
              <p>
                <img
                  style={{ width: "120px" }}
                  alt="coupon_icon"
                  src={couponDetails?.icon ? couponDetails?.icon : couponIcon}
                />
              </p>
            </div>
          </div>
        </DrawerContainer>
      </DrawerComp>
    </Container>
  );
};

export default Coupons;

const tableColumns = [
  {
    id: "id",
    label: "Coupon ID",
  },
  {
    id: "coupon_image",
    label: "Image",
  },
  {
    id: "title",
    label: "Coupon Title",
  },
  {
    id: "code",
    label: "Coupon Code",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "discount_type",
    label: "Discount Type",
  },
  {
    id: "discount",
    label: "Discount",
  },
  {
    id: "action",
    label: "Action",
  },
];

const statusList = [
  {
    id: true,
    title: "Active",
  },
  {
    id: false,
    title: "Inactive",
  },
];

const discountTypeList = [
  {
    id: 1,
    title: "Percentage",
  },
  {
    id: 2,
    title: "Amount",
  },
];
