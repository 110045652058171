import editIcon from "../../Assets/editIcon.png";
import delete_icon from "../../Assets/delete_icon.svg";
import availableDrone from "../../Assets/droneAvailable.svg";
import notAvilableDrone from "../../Assets/droneNotAvailable.svg";
import downloadIcon from "../../Assets/downloadDroneCert.svg";
import dummyDrone from "../../Assets/drone18x53.png";
import dummyBattery from "../../Assets/assetBattery.svg";

export const formatDroneMasterList = (
  data,
  handleEditDrone,
  handleDeleteDrone
) => {
  console.log(data, "rajeshData");
  const modifiedData = data?.map((item) => {
    console.log("DRONEMASTER", item);
    const {
      id,
      drone_image,
      banner,
      category: { title: cattitle },
      available_for_sale,
      certificate,
      title,
      location,
      model_no,
      price,
      is_available,
    } = item;
    return {
      id: <p style={{ fontSize: "12px" }}>{item?.id}</p>,
      drone_name: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <img
            src={drone_image ? drone_image : dummyDrone}
            style={{ width: "60px", height: "60px" }}
          />
          <p style={{ fontSize: "12px" }}>{title}</p>
        </div>
      ),
      drone_category: <p style={{ fontSize: "12px" }}>{cattitle}</p>,
      is_sale: (
        <img
          src={available_for_sale ? availableDrone : notAvilableDrone}
          alt={`${
            available_for_sale ? "available_drone" : "not_available_drone"
          }`}
        />
      ),
      certificate: (
        <div>
          {certificate ? (
            <a href={certificate} target="_blank">
              <img src={downloadIcon} />
            </a>
          ) : (
            <p
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              N.A
            </p>
          )}
        </div>
      ),
      banner: (
        <div>
          {banner ? (
            <img src={banner} style={{ width: "120px" }} />
          ) : (
            <p
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              N.A
            </p>
          )}
        </div>
      ),
      // certificate: "",
      // drone_qty: "",
      model_no: <p style={{ fontSize: "12px" }}>{model_no}</p>,
      drone_price: <p style={{ fontSize: "12px" }}>{`₹. ${price}`}</p>,
      drone_status: (
        <p
          style={{
            color: is_available ? "rgba(3, 98, 2, 1)" : "rgba(119, 0, 0, 1)",
            backgroundColor: is_available
              ? "rgba(198, 239, 205, 1)"
              : "rgba(255, 170, 170, 1)",
            padding: "10px 20px",
            borderRadius: "20px",
            boxSizing: "border-box",
            fontSize: "12px",
          }}
        >
          {is_available ? "Active" : "Inactive"}
        </p>
      ),
      actions: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "10px",
            justifyContent: "center",
          }}
        >
          <img
            src={editIcon}
            style={{ cursor: "pointer" }}
            onClick={() => handleEditDrone(item)}
          />

          <img
            src={delete_icon}
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteDrone(item?.slug)}
          />
        </div>
      ),
    };
  });
  return modifiedData;
};

export const formatBatteryMasterList = (
  data,
  handleEditBattery,
  handleDeleteBattery
) => {
  const modifiedData = data?.map((item) => {
    console.log("DRONEMASTER", item);
    const {
      id,
      drone: { title },
      image,

      name,
      power,
      quantity,
    } = item;
    return {
      id: <p style={{ fontSize: "12px" }}>{item?.id}</p>,
      name: <p style={{ fontSize: "12px" }}>{name}</p>,
      power: <p style={{ fontSize: "12px" }}>{power}</p>,
      // quantity: quantity,
      // certificate: certificate,
      drone: <p style={{ fontSize: "12px" }}>{title}</p>,
      image: (
        <img
          src={image ? image : dummyBattery}
          style={{ width: "80px", height: "80px" }}
          alt="battery_img"
        />
      ),

      actions: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "10px",
            justifyContent: "center",
          }}
        >
          <img
            src={editIcon}
            style={{ cursor: "pointer" }}
            onClick={() => handleEditBattery(item)}
          />

          <img
            src={delete_icon}
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteBattery(item?.id)}
          />
        </div>
      ),
    };
  });
  return modifiedData;
};

export const ValidateDrone = (value) => {
  return new Promise((resolve, reject) => {
    const {
      category_id,
      title,
      tagline,
      certification,
      model_no,
      slug,
      price,
      available_for_sale,
      is_available,
      about,
    } = value;

    const errors = { error: false };

    if (!title) {
      errors.title = "Please enter title";
      errors.error = true;
    }

    if (!tagline) {
      errors.tagline = "Please enter tagline";
      errors.error = true;
    }
    if (!certification) {
      errors.certification = "Please enter certification";
      errors.error = true;
    }
    if (!model_no) {
      errors.model_no = "Please enter model number";
      errors.error = true;
    }
    if (!price) {
      errors.price = "Please enter price";
      errors.error = true;
    }
    if (!about) {
      errors.about = "Please enter about the drone";
      errors.error = true;
    }
    if (!slug) {
      errors.slug = "Please enter slug";
      errors.error = true;
    }
    if (!category_id[0]) {
      errors.category_id = "Please select category";
      errors.error = true;
    }
    if (!available_for_sale[0]) {
      errors.available_for_sale = "Please select available for sale";
      errors.error = true;
    }
    if (!is_available[0]) {
      errors.is_available = "Please select status";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};

export const ValidateVehicle = (value) => {
  return new Promise((resolve, reject) => {
    const {
      name,
      company,

      status,
    } = value;

    const errors = { error: false };

    if (!name) {
      errors.name = "Please enter name";
      errors.error = true;
    }

    if (!company) {
      errors.company = "Please enter company";
      errors.error = true;
    }

    if (!status[0]) {
      errors.status = "Please enter status";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};

export const ValidateCharger = (value) => {
  return new Promise((resolve, reject) => {
    const { name, model, status, available_for_sale } = value;

    const errors = { error: false };

    if (!name) {
      errors.name = "Please enter name";
      errors.error = true;
    }

    if (!model) {
      errors.model = "Please enter model";
      errors.error = true;
    }

    if (!status[0]) {
      errors.status = "Please enter status";
      errors.error = true;
    }
    if (!available_for_sale[0]) {
      errors.available_for_sale = "Please enter avaialability";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};

export const ValidateBattery = (value) => {
  return new Promise((resolve, reject) => {
    const { name, power, drone_id } = value;

    const errors = { error: false };

    if (!name) {
      errors.name = "Please enter name";
      errors.error = true;
    }

    if (!power) {
      errors.power = "Please enter power";
      errors.error = true;
    }

    if (!drone_id[0]) {
      errors.drone_id = "Please select drone";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};

export const ValidateChargingHub = (value) => {
  return new Promise((resolve, reject) => {
    const { model, manufacturer } = value;

    const errors = { error: false };

    if (!model) {
      errors.model = "Please enter model";
      errors.error = true;
    }

    if (!manufacturer) {
      errors.manufacturer = "Please enter manufacturer";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};

export const ValidateLatopMaster = (value) => {
  return new Promise((resolve, reject) => {
    const { model, manufacturer } = value;

    const errors = { error: false };

    if (!model) {
      errors.model = "Please enter model";
      errors.error = true;
    }

    if (!manufacturer) {
      errors.manufacturer = "Please enter manufacturer";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
