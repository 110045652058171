import React, { useState, useEffect } from "react";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import CustomTable from "../../Component/CustomTable/CustomTable";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import { token_api, base_url } from "../../Utils/network";
import styles from "../DroneMaster/drone.module.css";
import {
  formatDroneInventoryList,
  formatDroneBatteryList,
  ValidateDrones,
} from "./utility";
import SearchAndDropdown from "../../Component/SearchAndDropdown/SearchAndDropdown";
import CustomSlider from "../../Component/CustomSlider/CustomSlider";
import CustomButton from "../../Component/CustomButton/CustomButton";
import filterIcon from "../../Assets/filterIcon.png";
import { Button } from "@mui/material";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import CustomDate from "../../Component/CustomDate/CustomDate";
import InputFields from "../../Component/InputFields/InputFields";
import { formatDate, helperdayMonthYearFull } from "../../Utils/helper";
import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";
import dayjs from "dayjs";
import editIcon from "../../Assets/edit_icon.svg";
import deleteIcon from "../../Assets/delete_icon.svg";
import DronePilot from "../../Component/DronePilot/DronePilot";
import CustomCard from "../../Component/CustomCard/CustomCard";
import pilotUser from "../../Assets/pilotUser.svg";
import dronePilot from "../../Assets/dronePilot.svg";
import batteriyInv from "../../Assets/batteryInv.svg";
import droneInventoryBook from "../../Assets/droneInventoryBook.svg";
import CustomModal from "../../Component/CustomModal/CustomModal";
import { getStatusStyles } from "../../Utils/Utils";
import blackArrow from "../../Assets/breadCrumbBack.svg";
import DroneInventoryComp from "../../Component/DroneInventoryComp/DroneInventoryComp";
import axios from "axios";

function DroneBatteries() {
  const [open, setOpen] = useState(false);
  const [locationListDraw, setLocationListDraw] = useState([]);
  const [droneInventoryList, setDroneInventoryList] = useState([]);
  const [typedValue, setTypedValue] = useState("");
  const [filters, setfilters] = useState({
    status: null,
    max: 0,
    min: undefined,
  });
  const [accForm, setaccForm] = useState({});
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [locationList, setLocationList] = useState([]);
  const [advFilter, setAdvFilter] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [droneList, setDroneList] = useState([]);
  const [filterDroneList, setFilterDroneList] = useState([]);

  const [formData, setFormData] = useState({
    id: null,
    drone_id: [],
    battery_id: [],
    // registration_date: null,
    serial_no: "",
    stationed_at_id: [],
    status: [],
  });
  const [formErrors, setFormErrors] = useState({});
  const [batteryList, setbatteryList] = useState([]);
  const [pilotSelected, setPilotSelected] = useState([]);

  const [cardData, setCardData] = useState({
    assign: { name: "Assigned", value: null },
    not_assign: { name: "Not Assigned", value: null },
    working: { name: "Working", value: null },
    damaged: { name: "Damaged", value: null },
    sold: { name: "Sold", value: null },
    repair: { name: "Under Repair", value: null },
    at_base: { name: "At Base", value: null },
    on_field: { name: "On Field", value: null },
    training: { name: "Training", value: null },
    survey_service: { name: "Survey Service", value: null },
    agri_service: { name: "Agri Service", value: null },
    no_category_assign: { name: "No Application Assigned", value: null },
  });

  const [assignPilot, setAssignPilot] = useState({
    battery_inventory_id: null,
    pilot_id: null,
    assigned_from: null,
    assigned_to: null,
  });

  const [assignInventoryObj, setAssignInventoryObj] = useState({});
  const [batteryAssignPilotHistory, setBatteryAssignPilotHistory] = useState(
    []
  );
  const [batteryAssetAssign, setBatteryAssetAssign] = useState({
    id: null,
    assigned_from: null,
    assigned_to: null,
    pilot: [],
  });
  const [assetAssignOpen, setAssetAssignOpen] = useState(false);
  const [openDailog, setOpenDailog] = useState(false);
  const getDashboardCount = () => {
    token_api
      .get(`drone/battery_inventory/dashboard_count`)
      .then((res) => {
        if (res.data.data) {
          const {
            assign,
            not_assign,
            damaged,
            sold,
            working,
            under_repair,
            agri_service,
            no_category_assign,
            survey_service,
            training,
          } = res.data.data;
          const _data = {
            assign: { name: "Assigned", value: assign },
            not_assign: { name: "Not Assigned", value: not_assign },
            damaged: { name: "Damaged", value: damaged },
            working: { name: "Working", value: working },
            sold: { name: "Sold", value: sold },
            repair: { name: "Under Repair", value: under_repair },
            at_base: { name: "At Base", value: 0 },
            on_field: { name: "On Field", value: 0 },
            training: { name: "Training", value: null },
            survey_service: { name: "Survey Service", value: null },
            agri_service: { name: "Agri Service", value: null },
            no_category_assign: {
              name: "No Application Assigned",
              value: null,
            },
          };
          setCardData(_data);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleEditBatteryInventory = (editObject) => {
    console.log(editObject, "editObject");

    const {
      id,
      inv_status,

      serial_no,
      // registration_date,
      stationed_at,
      battery,
      drone_inventory,
      pilot,
    } = editObject;

    console.log(editObject, "hariEditttt");

    setFormData((prev) => ({
      ...prev,
      drone_id: [drone_inventory]?.map((info) => ({
        title: info?.drone?.title,
        id: info?.id,
        drone_name: info?.drone_name,
      })),

      stationed_at_id: [stationed_at]?.map((info) => ({
        title: info?.city,
        id: info?.id,
      })),
      battery_id: [battery]?.map((info) => ({
        title: info?.name,
        id: info?.id,
      })),
      // registration_date: dayjs(registration_date),
      serial_no,
      status: statusOptions?.filter((info) => info?.id == inv_status),
      id,
    }));
    setOpen(true);
    setFormErrors({});
    setPilotSelected([pilot]);
  };

  const handleDeleteBatteryInventory = (id) => {
    token_api
      .delete(`/drone/battery/inventory/${id}/`)
      .then((response) => {
        if (
          response?.status == 200 ||
          response?.status == 201 ||
          response?.status == 202 ||
          response?.status == 204
        ) {
          alert("Battery Inventory Deleted Successfully!");
          getBatteryInventoryList();
          setPilotSelected([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStatus = (input) => {
    if (input) {
      return "Active";
    } else {
      return "InActive";
    }
  };
  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Working";
      case 2:
        return "Sold";
      case 3:
        return "Damaged";
      case 4:
        return "Under Repair";
      default:
        return "Unknown";
    }
  };

  const handleOpenAssignPilot = (inventoryId) => {
    console.log(inventoryId, "hariInventoryIddd");
    setOpenDailog(true);
    setAssignPilot({
      ...assignPilot,
      battery_inventory_id: inventoryId,
      assigned_from: null,
      assigned_to: null,
    });
    setPilotSelected([]);
  };
  const getBatteryInventoryList = async (filterobject) => {
    try {
      const fetchDroneInventory = await token_api.get(
        `/drone/battery/inventory`,
        {
          params: { ...filterobject },
          paramsSerializer: {
            indexes: null,
          },
        }
      );
      if (fetchDroneInventory.status !== 200) {
        throw new Error("Error in fetching");
      }
      setPaginationData(fetchDroneInventory?.data);
      // const modifiedData = formatDroneBatteryList(
      //   fetchDroneInventory?.data?.data,
      //   handleEditDrone,
      //   handleDeleteDrone
      // );

      const modifiedData = fetchDroneInventory?.data?.data.map(
        (item, index) => {
          const droneStatusStyles = getStatusStyles(item?.inv_status);

          return {
            id: (
              <p
                onClick={() => {
                  handleToShowAssignList(item);
                }}
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  fontSize: "12px",
                }}
              >
                {item?.id}
              </p>
            ),
            name: (
              <p style={{ fontSize: "12px" }}>{item?.battery?.name || "N.A"}</p>
            ),
            serial_no: <p style={{ fontSize: "12px" }}>{item?.serial_no}</p>,
            // registered_date: item?.registration_date
            //   ? helperdayMonthYearFull(item?.registration_date)
            //   : "N.A",
            // location: item?.stationed_at?.city || "N.A",
            updated_on: (
              <div style={{ fontSize: "12px" }}>
                {(item?.modified && helperdayMonthYearFull(item?.modified)) ||
                  "N.A"}
              </div>
            ),
            updated_by: (
              <div style={{ fontSize: "12px" }}>
                {item?.modified_by?.first_name && item?.modified_by?.last_name
                  ? `${item?.modified_by?.first_name} ${item?.modified_by?.last_name}`
                  : "NA"}{" "}
              </div>
            ),
            category: (
              <div style={{ fontSize: "12px" }}>
                {item?.drone_inventory?.drone?.category?.title || "N.A"}
              </div>
            ),
            power: (
              <p style={{ fontSize: "12px" }}>{item?.battery?.power || "NA"}</p>
            ),
            // quantity: item?.battery?.quantity || "Na",
            drone: (
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <div>
                  <img
                    src={item?.drone_inventory?.drone?.drone_image}
                    style={{ width: "56px", height: "56px" }}
                  />
                </div>
                <p style={{ fontSize: "12px" }}>
                  {" "}
                  {item?.drone_inventory?.drone?.title}
                </p>
              </div>
            ),
            assign: (
              <Button
                variant="contained"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleOpenAssignPilot(item?.id);
                }}
              >
                Assign
              </Button>
            ),

            inv_status: (
              <span
                style={{
                  padding: "5px",
                  borderRadius: "50px",
                  ...droneStatusStyles,
                  minWidth: "60%",
                  font: "bold",
                  display: "inline-block",
                  width: "100px",
                  fontSize: "12px",
                }}
              >
                {getStatusText(item?.inv_status)}
              </span>
            ),

            actions: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "10px",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleEditBatteryInventory(item)}
                >
                  <img src={editIcon} />
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteBatteryInventory(item?.id)}
                >
                  <img src={deleteIcon} />
                </span>
              </div>
            ),
          };
        }
      );
      console.log(modifiedData, "modifiedData");
      setDroneInventoryList(modifiedData);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(droneInventoryList, "hariDroneeeee");

  const getLocationList = (value) => {
    let apiEndpoint = "/course/city";

    if (value) {
      apiEndpoint += `?q=${value}`;
    }
    token_api
      .get(apiEndpoint)
      .then((res) => {
        if (res.data.data) {
          console.log(res.data.data, "newresdata");
          setLocationList(res?.data?.data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    let timer;

    if (typedValue) {
      timer = setTimeout(() => {
        getLocationList(typedValue);
      }, 500);
    } else {
      getLocationList();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [typedValue]);

  useEffect(() => {
    getDashboardCount();
  }, []);

  useEffect(() => {
    // getBatteriesList();
    const timeOut = setTimeout(() => {
      let filtersObject = {};

      if (filters?.q) {
        filtersObject.q = filters?.q;
      }
      if (pageState) {
        filtersObject.page = pageState;
      }
      if (filters?.status != null) {
        let abc = filters?.status[0]?.id;
        filtersObject.inv_status = abc;
      }
      if (filters?.is_assigned != null) {
        let abc = filters?.is_assigned[0]?.id;
        filtersObject.is_assigned = abc;
      }
      if (filters?.max) {
        filtersObject.max_qty = filters?.max;
      }
      if (filters?.min !== undefined) {
        filtersObject.min_qty = filters?.min;
      }
      if (filters?.category) {
        const ids = filters?.category?.map((info) => info?.id);
        filtersObject.category = ids;
      }
      if (filters?.drone) {
        const ids = filters?.drone?.map((info) => info?.slug);
        filtersObject.drone = ids;
      }

      getBatteryInventoryList(filtersObject);
    }, 500);

    return () => clearTimeout(timeOut);
  }, [
    filters?.q,
    filters?.status,
    filters?.max,
    filters?.min,
    filters?.category,
    filters?.drone,
    filters?.is_assigned,

    pageState,
  ]);

  const handleFilter = (e, type, selectName, multiple) => {
    console.log("HANDLERSS", e, type);
    if (type === "select" && selectName && multiple) {
      setfilters({
        ...filters,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setfilters({
        ...filters,
        [selectName]: [e],
      });
    }
  };

  const handleDeleteChip = (id, selectName) => {
    setfilters({
      ...filters,
      [selectName]: filters?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  const handleChangeforSearch = (event, newValue) => {
    setTypedValue(newValue);
    setaccForm((prev) => ({
      ...prev,
      location: [newValue],
    }));
  };

  const handleSliderChange = (value) => {
    // Handle the slider value change

    if (value == 1) {
      setfilters((prev) => ({
        ...prev,
        max: 0,
        min: undefined,
      }));
    } else {
      setfilters((prev) => ({
        ...prev,
        max: value,
        min: 1,
      }));
    }
  };

  console.log(filters, "divyaFilters");
  const handleAddEvent = () => {
    setOpen(true);
    setFormData({
      id: null,
      drone_id: [],
      battery_id: [],
      // registration_date: null,
      serial_no: "",
      stationed_at_id: [],
      status: [],
    });
    setFormErrors({});
    setPilotSelected([]);
  };

  const getLocationListforDrawer = () => {
    token_api
      .get(`course/location?page_size=100`)
      .then((res) => {
        if (res.data.data) {
          console.log(res?.data?.data, "responsedrtaa");
          let newres = res?.data?.data.map((info) => ({
            title: info?.city,
            id: info?.id,
          }));

          setLocationListDraw(newres);
        }
      })
      .catch((err) => console.log(err));
  };

  const getBatteryList = () => {
    token_api
      .get(`/drone/battery`)
      .then((res) => {
        if (res.data.data) {
          console.log(res?.data?.data, "batterylsit");
          let newres = res?.data?.data.map((info) => ({
            title: info?.name,
            id: info?.id,
          }));
          setbatteryList(newres);
        }
      })
      .catch((err) => console.log(err));
  };

  const getCategoryListing = () => {
    token_api
      .get(`drone/category`)
      .then((res) => {
        if (res.data.data) {
          console.log(res.data?.data, "responsex");

          setCategoryList(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getDroneList = () => {
    token_api
      .get(`drone/inventory?page_size=500`)
      .then((res) => {
        if (res.data.data) {
          console.log(res.data?.data, "responsex");

          let newres = res?.data?.data.map((info) => ({
            title: info?.drone_name,
            id: info?.id,
          }));
          setDroneList(newres);
        }
      })
      .catch((err) => console.log(err));
  };

  const getFilterDroneList = () => {
    token_api
      .get(`drone`)
      .then((response) => {
        if (response?.data?.data) {
          setFilterDroneList(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getBatteryList();
    getLocationListforDrawer();
    getCategoryListing();
    getDroneList();
    getFilterDroneList();
  }, []);

  const handleChange = (e, type, selectName, multiple) => {
    console.log("TYTYY");
    if (type === "select" && selectName && multiple) {
      setFormData({
        ...formData,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setFormData({
        ...formData,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    // const { name, target } = e.target;
  };

  const handleDeleteChipforDrawer = (id, selectName) => {
    console.log("DELETE", id, selectName, formData?.[selectName]);
    setFormData({
      ...formData,
      [selectName]: formData?.[selectName]?.filter((chip) => chip?.id !== id),
    });
    let namefiled = formData[selectName];
    console.log(namefiled[0]?.title, "slehdgfdf");
  };

  const handleAutocompleteChangeforPilot = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Pilot already included");
    } else {
      setPilotSelected(selectedValues);
    }
  };

  console.log(formData, "formData");

  const handleAddEventforBattery = () => {
    const {
      id,
      battery_id,
      // registration_date,
      licence_no,
      serial_no,
      stationed_at_id,
      status,
      drone_id,
    } = formData;

    let data = {
      drone_inventory_id: drone_id[0]?.id,
      // registration_date: formatDate(registration_date),
      serial_no,
      stationed_at_id: stationed_at_id[0]?.id,
      inv_status: status[0]?.id,
      battery_id: battery_id[0]?.id,
    };

    ValidateDrones(formData)
      .then((response) => {
        if (response == "success") {
          if (id) {
            token_api
              .patch(`/drone/battery/inventory/${id}/`, data)
              .then((res) => {
                if (
                  res.status == 200 ||
                  res.status == 201 ||
                  res.status == 204
                ) {
                  alert("Battery Inventory Updated Successfully!");

                  setFormData({
                    id: null,
                    drone_id: [],
                    battery_id: [],
                    // registration_date: null,
                    serial_no: "",
                    stationed_at_id: [],
                    status: [],
                  });
                  getBatteryInventoryList();
                  setOpen(false);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            token_api
              .post(`/drone/battery/inventory/`, data)
              .then((res) => {
                if (
                  res.status == 200 ||
                  res.status == 201 ||
                  res.status == 204
                ) {
                  let battery_inventory_id = res?.data?.data?.id;
                  alert("Battery Inventory Created Successfully!");
                  setFormData({
                    id: null,
                    battery_id: [],
                    drone_id: [],

                    // registration_date: null,
                    serial_no: "",
                    stationed_at_id: [],
                    status: [],
                  });
                  getBatteryInventoryList();
                  setOpen(false);
                  setPilotSelected([]);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      })
      .catch((err) => {
        setFormErrors(err);
      });
  };

  const handleCloseModal = () => {
    setOpenDailog(false);
    setAssignPilot({
      battery_inventory_id: null,
      pilot_id: null,
      assigned_from: null,
      assigned_to: null,
    });
  };

  console.log(assignPilot, "hariAssignPilot");
  const handleAssignPilot = () => {
    let data = {
      battery_inventory_id: assignPilot?.battery_inventory_id,
      pilot_id: pilotSelected[0]?.id,
      assigned_from: formatDate(assignPilot?.assigned_from),
      assigned_to: formatDate(assignPilot?.assigned_to),
    };

    token_api
      .post(`drone/pilot/battery_assign/`, data)
      .then((res) => {
        console.log(res);

        if (res?.status === 400) {
          console.log(res?.data, "hariResssss");
          alert(res?.data?.error?.fields[0]?.message[0]);
          setOpenDailog(false);
          // getBatteryInventoryList();
          setAssignPilot({
            battery_inventory_id: null,
            pilot_id: null,
            assigned_from: null,
            assigned_to: null,
          });
          setPilotSelected([]);
        }
        if (res.status == 200 || res.status == 201 || res.status == 204) {
          alert("Pilot Assigned Successfully!");
          setOpenDailog(false);
          // getBatteryInventoryList();
          setAssignPilot({
            battery_inventory_id: null,
            pilot_id: null,
            assigned_from: null,
            assigned_to: null,
          });
          setPilotSelected([]);
          if (assignInventoryObj?.id) {
            setPageState(1);
            getInventoryAssignedList();
          } else {
            setPageState(1);
            getBatteryInventoryList();
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleToShowAssignList = (inventoryobj) => {
    console.log(inventoryobj, "hariInvemamam");
    setAssignInventoryObj(inventoryobj);
  };

  const getInventoryAssignedList = async (filterobject) => {
    try {
      const fethLaptopInventoryAssignedList = await token_api.get(
        `drone/pilot/battery_assign?battery_inventory=${assignInventoryObj?.id}`,
        {
          params: { ...filterobject },
        }
      );

      if (fethLaptopInventoryAssignedList.status !== 200) {
        throw new Error("Error in fetching");
      }
      setPaginationData(fethLaptopInventoryAssignedList?.data);
      let modifiedArray = fethLaptopInventoryAssignedList?.data?.data?.map(
        (item) => {
          return {
            assigned_to: (
              <p
                style={{ fontSize: "12px" }}
              >{`${item?.pilot?.user?.first_name} ${item?.pilot?.user?.last_name}`}</p>
            ),
            assigned_from: (
              <p style={{ fontSize: "12px" }}>{item?.assigned_from || "N.A"}</p>
            ),
            assigned_till: (
              <p style={{ fontSize: "12px" }}>{item?.assigned_to || "N.A"}</p>
            ),
            handover_accepted_on: <p style={{ fontSize: "12px" }}>{"N.A"}</p>,
            handover_accepted_by: <p style={{ fontSize: "12px" }}>{"N.A"}</p>,
            updated_on: <p style={{ fontSize: "12px" }}>{"N.A"}</p>,
            modified_by: <p>{item?.modified_by || "N.A"}</p>,
            assign_by: <p>{item?.assign_by || "N.A"}</p>,
            created_on: (
              <p style={{ fontSize: "12px" }}>{item?.created_on || "N.A"}</p>
            ),
            // assign: (
            //   <Button
            //     variant="contained"
            //     style={{ cursor: "pointer" }}
            //     onClick={() => {
            //       handleOpenAssignPilot(assignInventoryObj?.id);
            //     }}
            //   >
            //     Assign
            //   </Button>
            // ),
            actions: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "10px",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleEditBatteryAssetAssign(item)}
                >
                  <img src={editIcon} />
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteBatteryAssetAssign(item?.id)}
                >
                  <img src={deleteIcon} />
                </span>
              </div>
            ),
          };
        }
      );
      setBatteryAssignPilotHistory([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditBatteryAssetAssign = (item) => {
    setBatteryAssetAssign({
      ...batteryAssetAssign,
      id: item?.id,
      assigned_from: dayjs(item?.assigned_from),
      assigned_to: dayjs(item?.assigned_to),
      pilot: [item?.pilot],
    });
    setAssetAssignOpen(true);
  };

  const handleDeleteBatteryAssetAssign = (assignId) => {
    token_api
      .delete(`drone/pilot/battery_assign/${assignId}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Battery Inventory Asset Assign Deleted Sucessfully!");
          setAssetAssignOpen(false);
          getInventoryAssignedList();
        }
      });
  };
  useEffect(() => {
    if (assignInventoryObj?.id) {
      const timeOut = setTimeout(() => {
        let filtersObject = {};

        if (pageState) {
          filtersObject.page = pageState;
        }

        getInventoryAssignedList(filtersObject);
      }, 500);

      return () => clearTimeout(timeOut);
    }
  }, [pageState, assignInventoryObj?.id]);

  console.log(
    assignInventoryObj,
    batteryAssignPilotHistory,
    "hariLaptopAssignHistory"
  );

  const handleHideAssignHistory = () => {
    setAssignInventoryObj({});
    getBatteryInventoryList();
  };

  const handleUpdateBatteryAssetAssign = () => {
    let data = {
      pilot_id: batteryAssetAssign?.pilot[0]?.id,
      assigned_from: formatDate(batteryAssetAssign?.assigned_from),
      assigned_to: formatDate(batteryAssetAssign?.assigned_to),
    };
    token_api
      .patch(`drone/pilot/battery_assign/${batteryAssetAssign?.id}/`, data)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Battery Asset Assign Details updated");
          setBatteryAssetAssign({
            id: null,
            assigned_from: null,
            assigned_to: null,
            pilot: [],
          });
          setAssetAssignOpen(false);
          getInventoryAssignedList();
        }
      });
  };

  const handleAutocompleteChangeforBatteryPilot = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Pilot already included");
    } else {
      setBatteryAssetAssign({
        ...batteryAssetAssign,
        ["pilot"]: selectedValues,
      });
    }
  };

  const handleAutocompleteChangefordroneInventory = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Drone inventory already included");
    } else {
      setFormData({
        ...formData,
        ["drone_id"]: selectedValues,
      });
    }
  };

  //Export Logic....

  const handleExportData = async () => {
    try {
      const response = await axios.get(
        `${base_url}drone/export/battery_inventory`
      );
      const excelUrl = response.data.data.data.excel_url;
      const link = document.createElement("a");
      link.href = excelUrl;
      link.setAttribute("download", `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error exporting the file", error);
    }
  };

  console.log(formErrors, "priyaFormData");
  return (
    <div>
      <div
        className={styles.pilotDash}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          // flexWrap: "wrap",
        }}
      >
        <div
          style={{
            minWidth: "30%",
            minHeight: "150px",
            margin: "0px 10px 0px 0px",
            alignSelf: "stretch",
          }}
        >
          <CustomCard
            cardIcon={pilotUser}
            data={{
              assign: cardData.assign,
              not_assign: cardData.not_assign,
            }}
            background={`linear-gradient(to left, rgb(81, 79, 255), rgb(87, 194, 255))`}
          />
        </div>
        <div
          style={{
            minWidth: "30%",
            minHeight: "150px",
            margin: "0px 10px 0px 10px",
            alignSelf: "stretch",
          }}
        >
          <CustomCard
            cardIcon={batteriyInv}
            data={{
              working: cardData.working,
              damaged: cardData.damaged,
              sold: cardData.sold,
              repair: cardData.repair,
            }}
            background={` linear-gradient(103.23deg, #57B67A -2.03%, #0B7754 96.35%)`}
          />
        </div>
        <div
          style={{
            minWidth: "30%",
            minHeight: "150px",
            margin: "0px 0px 0px 10px",
            alignSelf: "stretch",
          }}
        >
          <CustomCard
            cardIcon={droneInventoryBook}
            data={{
              at_base: cardData.at_base,
              on_field: cardData.on_field,
            }}
            background={`linear-gradient(to left, rgba(132, 98, 251, 1), rgba(235, 137, 235, 1))`}
          />
        </div>
      </div>

      {!assignInventoryObj?.id ? (
        <div style={{ marginTop: "20px" }}>
          <FilterContainer
            style={{ maxHeight: "40px", marginTop: "20px" }}
            // className={`${styles.marginTopBox}`}
          >
            <CustomSearchFilter
              placeholder="Search by battery inventory name, serial no, category..."
              onChange={(e) => {
                setPageState(1);
                setfilters({ ...filters, q: e });
              }}
            />

            <CustomSelectChip
              multiple={false}
              label="Filter By Drone"
              name="drone"
              listArray={filterDroneList}
              onChange={handleFilter}
              onDelete={handleDeleteChip}
              value={filters?.drone}
            />

            <CustomSelectChip
              multiple={false}
              label="Filter By Category"
              name="category"
              listArray={categoryList}
              onChange={handleFilter}
              onDelete={handleDeleteChip}
              value={filters?.category}
            />

            <CustomSelectChip
              multiple={false}
              label="Filter By Assigned Status"
              name="is_assigned"
              listArray={assignStatus}
              onChange={handleFilter}
              onDelete={handleDeleteChip}
              value={filters?.is_assigned}
            />

            {/* <SearchAndDropdown
              label="Filter by Location"
              value={accForm?.location}
              onChange={handleChangeforSearch}
              listing={locationList}
              typedValue={typedValue}
              setTypedValue={setTypedValue}
            /> */}

            {/* <CustomSelectChip
          multiple={false}
          label="Filter By Status"
          name="status"
          onChange={handleFilter}
          onDelete={handleDeleteChip}
          value={filters?.status}
          listArray={statusOptions}
        /> */}

            <CustomButton
              text={"ADVANCE FILTERS"}
              btnIcon={<img src={filterIcon} alt="adv-filter" />}
              onClick={() => setAdvFilter((prev) => !prev)}
              // maxWidth="170px"
            />

            <CustomButton
              text={"Export"}
              maxWidth="110px"
              onClick={handleExportData}
            />
            <Button
              variant="contained"
              onClick={handleAddEvent}
              sx={{ height: "53px" }}
            >
              Add
            </Button>
          </FilterContainer>

          {advFilter && (
            <FilterContainer style={{ maxHeight: "40px", marginTop: "20px" }}>
              <CustomSlider
                style={{ width: "100%" }}
                min={1}
                max={100}
                step={1}
                defaultValue={filters?.max}
                onChange={handleSliderChange}
                primaryColor="#E95E33"
                label="Quantity"
              />

              {/* <CustomSelectChip
                multiple={false}
                label="Filter By Status"
                name="status"
                listArray={statusOptions}
                onChange={handleFilter}
                onDelete={handleDeleteChip}
                value={filters?.is_assigned}
              /> */}
            </FilterContainer>
          )}

          <div className={styles.tableContainer}>
            <CustomTable
              columns={tableColumns}
              data={droneInventoryList}
              // handleClick={handleRedirect}
              // tableFor=""
            />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        </div>
      ) : (
        <div style={{ marginTop: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <img
                src={blackArrow}
                onClick={handleHideAssignHistory}
                style={{ cursor: "pointer" }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4>{assignInventoryObj?.id}</h4>
                <p style={{ fontSize: "14px" }}>Battery Inventory ID</p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4>{assignInventoryObj?.battery?.name || "N.A"}</h4>
                <p style={{ fontSize: "14px" }}>Battery Name</p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4>{assignInventoryObj?.battery?.power || "N.A"}</h4>
                <p style={{ fontSize: "14px" }}>Battery Power</p>
              </div>
            </div>
            <div>
              <Button
                variant="contained"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleOpenAssignPilot(assignInventoryObj?.id);
                }}
              >
                Assign
              </Button>
            </div>
          </div>
          <div className={styles.tableContainer}>
            <CustomTable
              columns={assignPilotColumns}
              data={batteryAssignPilotHistory}
              // handleClick={handleRedirect}
              // tableFor=""
            />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        </div>
      )}

      <DrawerComp open={open} onClose={() => setOpen(false)} anchor="right">
        <DrawerContainer>
          <DrawerTitle
            text={
              formData?.id
                ? "Update Battery Inventory"
                : "Add Battery Inventory"
            }
            handleCancelDrw={() => setOpen((prev) => !prev)}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            <CustomSelectChip
              multiple={false}
              label="Select battery"
              name="battery_id"
              onChange={handleChange}
              onDelete={handleDeleteChipforDrawer}
              value={formData?.battery_id}
              listArray={batteryList}
              error={formErrors?.battery_id}
            />

            {/* <CustomDatePicker
              label="Registration Date"
              value={formData?.registration_date}
              onChange={(newValue) =>
                setFormData({
                  ...formData,
                  registration_date: newValue,
                })
              }
              error={formErrors?.registration_date}
            /> */}

            <InputFields
              label="Serial Number"
              name="serial_no"
              value={formData?.serial_no || ""}
              onChange={handleChange}
              error={formErrors?.serial_no}
              helperTex={formErrors?.serial_no}
            />

            {/* <CustomSelectChip
              multiple={false}
              label="Select Drone Inventory"
              name="drone_id"
              onChange={handleChange}
              onDelete={handleDeleteChip}
              value={formData?.drone_id}
              listArray={droneList}
              error={formErrors?.drone_id}
            /> */}
            <DroneInventoryComp
              value={formData?.drone_id}
              onChange={handleAutocompleteChangefordroneInventory}
            />
            {formErrors?.drone_id && (
              <p style={{ color: "red" }}>{formErrors?.drone_id}</p>
            )}

            {/* <CustomSelectChip
              multiple={false}
              label="Location"
              name="stationed_at_id"
              onChange={handleChange}
              onDelete={handleDeleteChipforDrawer}
              value={formData?.stationed_at_id}
              listArray={locationListDraw}
              error={formErrors?.stationed_at_id}
            /> */}

            <CustomSelectChip
              multiple={false}
              label="Inventory Status"
              name="status"
              onChange={handleChange}
              onDelete={handleDeleteChipforDrawer}
              value={formData?.status}
              listArray={statusOptions}
              error={formErrors?.status}
            />

            {/* <DronePilot
              value={pilotSelected}
              onChange={handleAutocompleteChangeforPilot}
            />
            {formErrors?.pilotSelected && (
              <p style={{ color: "red" }}>{formErrors?.pilotSelected}</p>
            )} */}

            <div>
              <Button variant="contained" onClick={handleAddEventforBattery}>
                {formData?.id ? "Update" : "Add"}
              </Button>
            </div>
          </div>
        </DrawerContainer>
      </DrawerComp>

      <DrawerComp
        open={assetAssignOpen}
        onClose={() => setAssetAssignOpen(false)}
        anchor="right"
      >
        <DrawerContainer>
          <DrawerTitle
            text={"Update Pilot Assign Details"}
            handleCancelDrw={() => setAssetAssignOpen((prev) => !prev)}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            <DronePilot
              value={batteryAssetAssign?.pilot}
              onChange={handleAutocompleteChangeforBatteryPilot}
            />

            <CustomDatePicker
              label="Assigned From"
              onChange={(newValue) => {
                setBatteryAssetAssign({
                  ...batteryAssetAssign,
                  assigned_from: newValue,
                });
              }}
              value={batteryAssetAssign?.assigned_from}
            />

            <CustomDatePicker
              label="Assigned Till"
              onChange={(newValue) => {
                setBatteryAssetAssign({
                  ...batteryAssetAssign,
                  assigned_to: newValue,
                });
              }}
              value={batteryAssetAssign?.assigned_to}
            />

            <div>
              <Button
                variant="contained"
                onClick={handleUpdateBatteryAssetAssign}
              >
                {"Update"}
              </Button>
            </div>
          </div>
        </DrawerContainer>
      </DrawerComp>

      <CustomModal open={openDailog} onClose={handleCloseModal}>
        <div>
          <h3 style={{ marginBottom: "10px" }}>Assigned Pilot</h3>
          <div style={{ marginTop: "10px" }}>
            <CustomDatePicker
              label="Assigned From"
              value={assignPilot?.assigned_from}
              onChange={(newValue) =>
                setAssignPilot({
                  ...assignPilot,
                  assigned_from: newValue,
                })
              }
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <CustomDatePicker
              label="Assigned Till"
              value={assignPilot?.assigned_to}
              onChange={(newValue) =>
                setAssignPilot({
                  ...assignPilot,
                  assigned_to: newValue,
                })
              }
            />
          </div>{" "}
          <div style={{ margin: "10px 0px" }}>
            <DronePilot
              value={pilotSelected}
              onChange={handleAutocompleteChangeforPilot}
            />
          </div>
          <Button variant="contained" onClick={handleAssignPilot}>
            Submit
          </Button>
        </div>
      </CustomModal>
    </div>
  );
}

export default DroneBatteries;

const tableColumns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Battery Name",
  },
  {
    id: "serial_no",
    label: "Serial No",
  },
  {
    id: "drone",
    label: "Drone",
  },
  {
    id: "category",
    label: "Category",
  },
  {
    id: "power",
    label: "Power",
  },

  // {
  //   id: "quantity",
  //   label: "Quantity",
  // },
  // {
  //   id: "location",
  //   label: "Location",
  // },
  // {
  //   id: "registered_date",
  //   label: "Registration Date",
  // },
  {
    id: "updated_on",
    label: "Updated On",
  },
  {
    id: "updated_by",
    label: "Updated By",
  },
  {
    id: "assign",
    label: "Assign",
  },
  {
    id: "inv_status",
    label: "Status",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

const statusOptions = [
  { title: "Working", id: 1 },
  { title: "Sold", id: 2 },
  { title: "Damaged", id: 3 },
  { title: "Under Repair", id: 4 },
];

const assignPilotColumns = [
  {
    id: "assigned_to",
    label: "Assigned To",
  },
  {
    id: "assigned_from",
    label: "Assigned From",
  },
  {
    id: "assigned_till",
    label: "Assigned Till",
  },
  {
    id: "handover_accepted_on",
    label: "Handover Accepted On",
  },
  {
    id: "handover_accepted_by",
    label: "Handover Accepted By",
  },
  {
    id: "updated_on",
    label: "Updated On",
  },
  {
    id: "modified_by",
    label: "Updated By",
  },
  {
    id: "assign_by",
    label: "Created By",
  },
  {
    id: "created_on",
    label: "Created On",
  },
  // {
  //   id: "assign",
  //   label: "Assign Pilot",
  // },

  {
    id: "actions",
    label: "Actions",
  },
];

const assignStatus = [
  {
    title: "Assigned",
    id: true,
  },
  {
    title: "Not Assigned",
    id: false,
  },
];
