export const validateService = (value) => {
  return new Promise((resolve, reject) => {
    const {
      title,
      slug,
      descriptions,
      about,
      // price,
      index,
      category,
      // area_covered,
      // duration,
    } = value;

    const errors = { error: false };

    if (!title) {
      errors.title = "Service title cannot be empty";
      errors.error = true;
    }

    // Check if slug is empty
    if (!slug || slug.trim() === "") {
      errors.slug = "Slug cannot be empty";
      errors.error = true;
    }

    // Check if slug contains '/'
    if (slug && slug.includes("/")) {
      errors.slug = "Slug cannot contain '/'";
      errors.error = true;
    }

    if (!category || !category[0]) {
      errors.category = "Enter Category";
      errors.error = true;
    }

    if (!descriptions) {
      errors.descriptions = "Service Description cannot be empty";
      errors.error = true;
    }
    if (!about) {
      errors.about = "About Service cannot be empty";
      errors.error = true;
    }
    // if (!price) {
    //   errors.price = "Service price cannot be empty";
    //   errors.error = true;
    // }

    // if (!area_covered) {
    //   errors.area_covered = "Area Covered cannot be empty";
    //   errors.error = true;
    // }
    // if (!duration) {
    //   errors.duration = "Duration cannot be empty";
    //   errors.error = true;
    // }

    if (!index) {
      errors.index = "Service Index cannot be empty";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
