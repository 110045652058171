import React, { useState, useEffect, useContext } from "react";
import styles from "./Organization.module.css";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import CustomButton from "../../Component/CustomButton/CustomButton";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomSelect from "../../Component/CustomSelect/CustomSelect";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import { Checkbox } from "@mui/material";
import filterIcon from "../../Assets/filterIcon.png";
import hotelIcon from "../../Assets/hotelsIcon.svg";
import organizationNo from "../../Assets/organizationNo.svg";
import organizationFigma from "../../Assets/organizationFigma.svg";
import DashboardCard from "../../Component/DashboardCard/DashboardCard";
import { Link, useNavigate } from "react-router-dom";
import { token_api } from "../../Utils/network";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import defaultImg from "../../Assets/defaultImg.svg";
import delete_icon from "../../Assets/delete_icon.svg";
import edit_icon from "../../Assets/edit_icon.svg";
import InputFields from "../../Component/InputFields/InputFields";
import { Button } from "@mui/material";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import SearchAndDropdown from "../../Component/SearchAndDropdown/SearchAndDropdown";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";

const Organization = () => {
  const navigate = useNavigate();
  const { user_permissions } = useContext(UserCredsContext);

  console.log(user_permissions, "organizationUserr");
  const [orgCount, setorgCount] = useState({ orgcdata: "" });
  const [filters, setfilters] = useState({});
  const [typedValue, setTypedValue] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [organizationList, setOrganizationList] = useState([]);
  const [locationList, setLocationList] = useState([]);

  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Organizations</div>,
  ];

  const handleRedirect = (id) => {
    console.log("IFIDCOME", id);
    navigate(`/users/edit/createorganization/${id}`);
  };

  const getOrganizationsList = async (filters) => {
    try {
      const fetchOrganizationData = await token_api.get(`users/organizations`, {
        params: { ...filters },
      });
      if (fetchOrganizationData?.status !== 200) {
        throw new Error("Error while fething Organizations");
      }
      setPaginationData(fetchOrganizationData?.data);
      setorgCount((prev) => ({
        ...prev,
        orgcdata: fetchOrganizationData?.data?.total_count,
      }));

      let modifiedArray = fetchOrganizationData?.data?.data.map((item) => {
        // const { bookingaddonservice } = item;

        // const city = item?.location?.map((info) => info?.city).join(", ");

        return {
          // checkBox: (
          //   <Checkbox
          //     checked={false}
          //     // onChange={handleChange}
          //     inputProps={{ "aria-label": "controlled" }}
          //   />
          // ),
          organizationId: (
            <div
              style={{ cursor: "pointer" , fontSize:"12px"}}
              onClick={() => handleRedirect(item?.id)}
            >
              {item?.id}
            </div>
          ),

          organizationName: (
            <p
              style={{ cursor: "pointer" , fontSize:"12px"}}
              onClick={() => handleRedirect(item?.id)}
            >
              {item?.name}
            </p>
          ),
          location: (
            <p
              style={{ cursor: "pointer", fontSize:"12px" }}
              onClick={() => handleRedirect(item?.id)}
            >
              {item?.line_1 && `${item.line_1}, `}
              {item?.line_2 && `${item.line_2}, `}
              {item?.area && `${item.area}, `}
              {item?.city && `${item.city}, `}
              {item?.tehsil && `${item.tehsil}, `}
              {item?.district && `${item.district}, `}
              {item?.state && `${item.state}, `}
              {item?.country && `${item.country}, `}
              {item?.pincode && `${item.pincode}`}
            </p>
          ),

          since: (
            <div
              style={{ cursor: "pointer", fontSize:"12px" }}
              onClick={() => handleRedirect(item?.id)}
            >
              {item?.since_year}
            </div>
          ),
          boarding: (
            <div
              style={{ cursor: "pointer" , fontSize:"12px"}}
              onClick={() => handleRedirect(item?.id)}
            >
              {item?.on_boarding_date}
            </div>
          ),
          logo: item?.logo ? (
            <img
              src={item?.logo}
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          ) : (
            <img
              src={defaultImg}
              style={{ width: "60px", height: "60px", objectFit: "cover" }}
            />
          ),
          action: (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {user_permissions?.update && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleRedirect(item?.id)}
                >
                  <img src={edit_icon} />
                </div>
              )}

              {user_permissions?.delete && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteorg(item?.id)}
                >
                  <img src={delete_icon} />
                </div>
              )}
            </div>
          ),
        };
      });
      setOrganizationList([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };

  const getLocationList = (value) => {
    let apiEndpoint = "/course/location";

    if (value) {
      apiEndpoint += `?q=${value}`;
    }
    token_api
      .get(apiEndpoint)
      .then((res) => {
        if (res.data.data) {
          console.log(res.data.data, "newresdata");
          let userloc = res.data.data?.map((info) => ({
            id: info?.id,
            title: `${info?.line_1}, ${info?.line_2}, ${info?.area}, ${info?.city_new}, ${info?.state}, ${info?.country}`,
          }));
          setLocationList(userloc);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      let filterObject = {};
      if (pageState) {
        filterObject.page = pageState;
      }
      if (filters?.name) {
        filterObject.name = filters?.name;
      }
      if (filters?.location) {
        filterObject.location = filters?.location[0]?.id;
      }
      if (filters?.year && filters.year.length === 4) {
        filterObject.year = filters.year;
      }

      getOrganizationsList(filterObject);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [pageState, filters?.name, filters?.location, filters?.year]);

  useEffect(() => {
    getLocationList();
  }, []);

  const handleFilter = (e, type, selectName, multiple) => {
    console.log("HANDLERSS", e, type);
    if (type === "select" && selectName && multiple) {
      setfilters({
        ...filters,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setfilters({
        ...filters,
        [selectName]: [e],
      });
    }
  };

  const handleDeleteChip = (id, selectName) => {
    setfilters({
      ...filters,
      [selectName]: filters?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  console.log(filters, "hariFiltersss");

  const handleDeleteorg = (deleteid) => {
    token_api
      .delete(`/users/organizations/${deleteid}/`)
      .then((response) => {
        if (response?.status == 204 || response?.status == 201) {
          getOrganizationsList();
        }
      })
      .catch((error) => {});
  };

  const handleAddnewOrganization = () => {
    navigate(`/users/createorganization`);
  };
  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };

  const handleChangeforSearch = (event, newValue) => {
    setTypedValue(newValue);
    setfilters((prev) => ({
      ...prev,
      location: [newValue],
    }));
  };

  useEffect(() => {
    let timer;

    if (typedValue) {
      timer = setTimeout(() => {
        getLocationList(typedValue);
      }, 500);
    } else {
      getLocationList();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [typedValue]);
  return (
    <Container style={{ overflowX: "auto" }}>
      <div className="flexBetween">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>{" "}
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        </div>

        {user_permissions?.write && (
          <Link to="/users/createorganization">
            <Button variant="contained" className="btnLinearGradient">
              + New Organization{" "}
            </Button>
          </Link>
        )}
      </div>
      <div
        style={{
          display: "flex",
          columnGap: "20px",
          width: "300px",
          margin: "20px 0px",
        }}
      >
        <DashboardCard
          cardTitle="Organization"
          cardIcon={hotelIcon}
          primaryGardient="linear-gradient(rgba(124, 147, 195, 1),
              rgba(160, 181, 224, 0.77))"
          secondaryGradient="linear-gradient(rgba(88, 116, 174, 1),
          rgba(124, 147, 195, 0.42))"
          value="4"
          countvalue={orgCount}
          hint="orgcdata"
          present={true}
        />
      </div>
      {/* const [orgCount, setorgCount] = useState({ orgcdata: "" }); */}

      <FilterContainer
        className={styles.filterContainer}
        // className={`${styles.marginTopBox}`}
      >
        <CustomSearchFilter
          placeholder="Search by name of the organization..."
          value={filters?.name}
          onChange={(e) => {
            // setPageState(1);
            setfilters({ ...filters, name: e });
          }}
        />

        {/* <CustomSelectChip
          label={"Filter by Location"}
          listArray={locationList}
          minWidth={"175px"}
          multiple={false}
          name="location"
          value={filters?.location}
          onChange={handleFilter}
          onDelete={handleDeleteChip}
        /> */}

        {/* <SearchAndDropdown
          label="Filter by Location"
          value={filters?.location}
          onChange={handleChangeforSearch}
          listing={locationList}
          typedValue={typedValue}
          setTypedValue={setTypedValue}
        /> */}

        {/* <CustomSearchFilter
          value={filters?.year}
          onChange={(e) => {
            // setPageState(1);
            setfilters({ ...filters, year: e });
          }}
        /> */}

        <InputFields
          label="Fiter by boarding year"
          name="year"
          type="number"
          value={filters?.year}
          onChange={(e) => {
            // setPageState(1);
            setfilters({ ...filters, year: e.target.value });
          }}
          maxLength={4}
        />

        {/* <CustomButton
          text={"ADVANCE FILTERS"}
          btnIcon={<img src={filterIcon} alt="adv-filter" />}
          // maxWidth="170px"
        /> */}
        <CustomButton
        
          text={"Export"}
          maxWidth="110px"
          onClick={() => setOpenDrawer(true)}
        />
      </FilterContainer>
      <div className={styles.tableContainer}>
        <CustomTable
          columns={tableColumns}
          data={organizationList}
          handleClick={handleRedirect}
          tableFor="hotels"
        />
      </div>
      <div
        className={styles.paginationContainer}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* <Link to="/users/createorganization" style={{ textDecoration: "none" }}>
          <h3
            style={{
              color: "gray",
              fontWeight: "400",
              cursor: "pointer",
              marginTop: "5px",
            }}
          >
            + New Organization
          </h3>
        </Link> */}
        <CustomPagination max_pages={max_pages} setPageState={setPageState} />
      </div>
    </Container>
  );
};

export default Organization;

const tableColumns = [
  // {
  //   id: "checkBox",
  //   label: "",
  // },
  {
    id: "organizationId",
    label: "Organization ID",
  },
  {
    id: "organizationName",
    label: "Name Of Organization",
  },

  {
    id: "location",
    label: "Location",
  },

  {
    id: "since",
    label: "Since Year",
  },
  {
    id: "boarding",
    label: "On Boarding Date",
  },
  {
    id: "logo",
    label: "Logo",
  },
  {
    id: "action",
    label: "Action",
  },
];

const tableBodyData = [
  {
    id: 1,
    organizationId: "HI10029",
    organizationName: "Lorem Ipsum",
    location: "1st floor 108, Veera Desai Road,andheri west, Mumbai",
    since: "2014",

    boarding: "12th Sept 2022",
    logo: organizationFigma,
  },
  {
    id: 2,
    organizationId: "HI10029",
    organizationName: "Lorem Ipsum",
    location: "1st floor 108, Veera Desai Road,andheri west, Mumbai",
    since: "2014",

    boarding: "12th Sept 2022",
    logo: organizationNo,
  },
  {
    id: 3,
    organizationId: "HI10029",
    organizationName: "Lorem Ipsum",
    location: "1st floor 108, Veera Desai Road,andheri west, Mumbai",
    since: "2014",

    boarding: "12th Sept 2022",
    logo: organizationFigma,
  },
];
