import React, { useContext, useEffect, useState, useRef } from "react";
import styles from "./CourseList.module.css";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import { courseCardData } from "../../Utils/constants";
import DashboardCard from "../../Component/DashboardCard/DashboardCard";
import CustomTabs3 from "../../Component/CustomTabsNew/CustomTabs3";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomSelect from "../../Component/CustomSelect/CustomSelect";
import CustomButton from "../../Component/CustomButton/CustomButton";
import CustomDate from "../../Component/CustomDate/CustomDate";
import filterIcon from "../../Assets/filterIcon.png";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import { Button } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { token_api, base_url } from "../../Utils/network";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import CustomSlider from "../../Component/CustomSlider/CustomSlider";
import delete_icon from "../../Assets/delete_icon.svg";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import SearchAndDropdown from "../../Component/SearchAndDropdown/SearchAndDropdown";
import editIcon from "../../Assets/edit_icon.svg";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import deleteIcon from "../../Assets/delete_icon.svg";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import InputFields from "../../Component/InputFields/InputFields";
import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";
import DurationPicker from "../../Component/DurationPicker/DurationPicker";
import SearchInstructorAndDropDown from "../../Component/SearchInstructorAndDropDown/SearchInstructorAndDropDown";
import TextEditor from "../../Component/TextEditor/TextEditor";
import { formatDate, handleKeyPress } from "../../Utils/helper";
import CustomizedSwitches from "../../Component/CustomSwitch/CustomSwitch";
import StartAndEndDateDropdown from "../../Component/StartAndEndDateDropdown/StartAndEndDateDropdown";
import { validateBatch } from "./validate";
import CourseComboBoxCom from "../../Component/CourseComboBoxCom/CourseComboBoxCom";
import axios from "axios";
import dayjs from "dayjs";
const CoursesList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const editorRef = useRef(null);
  const { user_permissions } = useContext(UserCredsContext);
  console.log(user_permissions, "courseUserPermissions");
  const [filters, setfilters] = useState({ max: 0, min: undefined, q: "" });
  const [typedValue, setTypedValue] = useState("");
  const [courseDashBoardData, setCourseDashBoardData] = useState({});
  const [advFilter, setAdvFilter] = useState(false);
  const [courseList, setCourseList] = useState([]);
  const [batchListing, setBatchListing] = useState([]);
  const [tabValue, setTabValue] = useState(1);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [bookingList, setBookingList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [types, setTypes] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [editBatchIndex, seteditBatchIndex] = useState(null);
  const [openBatchDrawer, setOpenBatchDrawer] = useState(false);

  const [instructorList, setInstructorList] = useState([]);
  const [courseDaysList, setCourseDaysList] = useState([]);
  const [courses, setCourses] = useState([]);

  //Batch Form Data...

  const [batchForm, setbatchForm] = useState({
    id: null,
    name: "",
    course: [],
    start_date: null,
    end_date: null,
    start_time: "",
    end_time: null,
    mrp: "",
    selling_price: "",
    total_seats: "",
    location: [],
    discount: "",
    days_of_weeks: [],
    mode: [],
    language: [],
    instructors: [],
    descriptions: "",
    flying_start_date: null,
    flying_end_date: null,
    flying_slot_id: [],
    zoom_link: "",
  });

  console.log(batchForm, "divyaBatchForm");
  const [formErrors, setFormErrors] = useState({});
  const [isOfflineMode, setIsOfflineMode] = useState(false);
  const [switchValue, setSwitchValue] = useState(false);
  const [flyingSlotList, setFlyingSlotList] = useState([]);
  const [locationtypedValue, setLocationTypedValue] = useState("");

  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Course Listing</div>,
  ];
  const handleRedirect = (slug) => {
    console.log("IFIDCOME", slug);
    navigate(`/lms/courses/edit-course/${slug}`);
  };

  const getInstructor = () => {
    token_api
      .get(`course/instructor`)
      .then((response) => {
        if (response.status === 200) {
          const modifiedList = response?.data?.data?.map((item) => {
            return {
              id: item?.id,
              title: item?.name,
            };
          });
          setInstructorList(modifiedList);
        }
      })
      .catch((err) => {});
  };

  const getCourseDays = () => {
    token_api
      .get(`course/days`)
      .then((response) => {
        if (response.status === 200) {
          const modifiedList = response?.data?.data?.map((item) => {
            return {
              id: item?.id,
              title: item?.weekday,
            };
          });
          setCourseDaysList(modifiedList);
        }
      })
      .catch((err) => {});
  };

  const handleSwitchChange = (newValue) => {
    setSwitchValue(newValue);
  };

  //Batch crud operations...

  const handleAddBatchOpenDrawer = () => {
    setbatchForm({
      id: null,
      name: "",
      course: [],
      start_date: null,
      end_date: null,
      start_time: "",
      end_time: null,
      mrp: "",
      selling_price: "",
      total_seats: "",
      location: [],
      discount: "",
      days_of_weeks: [],
      mode: [],
      language: [],
      instructors: [],
      descriptions: "",
      flying_start_date: null,
      flying_end_date: null,
      flying_slot_id: [],
      zoom_link: "",
    });
    setFormErrors({});
    setOpenBatchDrawer(true);
  };
  const handleCloseDrawer = () => {
    seteditBatchIndex(null);
    setOpenBatchDrawer(false);
  };

  const handleDeleteorg = (deleteid) => {
    token_api
      .delete(`/course/${deleteid}/`)
      .then((response) => {
        if (response?.status === 204 || response?.status === 201) {
          alert("Course successfully removed");
          getCourseListing();
        } else {
          throw new Error(response?.data?.error?.message);
          // console.log(response?.data?.error?.message            ,"responsedo")
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const getTotalSeatsFromBatches = (batchesData) => {
    if (batchesData.length === 1) {
      return batchesData[0]?.total_seats;
    } else {
      let total_seats = batchesData.reduce(
        (acc, curr) => acc + (curr?.total_seats || 0),
        0
      );
      return total_seats;
    }
  };
  const getCourseListing = async (filterObject) => {
    try {
      const fetchList = await token_api.get(`course`, {
        params: { ...filterObject },
        paramsSerializer: {
          indexes: null,
        },
      });
      console.log(fetchList, "tableBodyddd");
      if (fetchList?.status !== 200) {
        throw new Error("Network Error");
      }
      setPaginationData(fetchList?.data);

      let modifiedArray = fetchList?.data?.data?.map((item) => {
        console.log(item, "fetchList");
        // const { bookingaddonservice } = item;

        return {
          id: (
            <div
              style={{ fontSize: "12px" }}
              // style={{ cursor: "pointer" }}
              // onClick={() => handleRedirect(item?.slug)}
            >
              {item?.id}
            </div>
          ),
          name: (
            <div
              // style={{ cursor: "pointer" }}
              // onClick={() => handleRedirect(item?.slug)}
              style={{ fontSize: "12px" }}
            >
              {" "}
              {item?.name}
            </div>
          ),
          course_type: (
            <p style={{ fontSize: "12px" }}>{item?.type?.title || "N.A"}</p>
          ),
          language: (
            <div className={styles.languageCon}>
              {item?.languages?.length > 0 && (
                <div style={{ display: "flex" }}>
                  <p style={{ margin: "0px", textAlign: "center" }}>
                    {item.languages.map((language, index) => {
                      return (
                        <span style={{ fontSize: "12px" }}>
                          {" "}
                          {language}
                          {index < item.languages.length - 1 && ", "}
                        </span>
                      );
                    })}
                  </p>
                  {/* {item.languages.length > 1 && (
                    <p style={{ margin: "0px", textAlign: "center" }}>...</p>
                  )} */}
                </div>
              )}
            </div>
          ),

          duration: (
            <p style={{ fontSize: "12px" }}>{`${item?.duration || "N.A"}`}</p>
          ),
          seats: `${
            item?.batches?.length > 0 ? (
              <p style={{ fontSize: "12px" }}>
                {getTotalSeatsFromBatches(item?.batches)}
              </p>
            ) : (
              "N.A"
            )
          }`,
          location: (
            <div style={{ fontSize: "12px" }}>
              {item?.batches[0]?.location?.line_1},{" "}
              {item?.batches[0]?.location?.line_2},
              {item?.batches[0]?.location?.area},
              {item?.batches[0]?.location?.city},
              {item?.batches[0]?.location?.state},
              {item?.batches[0]?.location?.country},
              {item?.batches[0]?.location?.pincode},
            </div>
          ),

          batches: (
            <p style={{ fontSize: "12px" }}>{`${
              item?.batches?.length || "N.A"
            }`}</p>
          ),
          lowest_price: (
            <p style={{ fontSize: "12px" }}>{`Rs.${
              item?.lowest_price || "N.A"
            }`}</p>
          ),
          instructors: (
            <div className={styles.instructorCon}>
              {item?.instructors?.length > 0 && (
                <div className={styles.instructor} style={{ fontSize: "12px" }}>
                  {item.instructors[0].name}
                  {item.instructors.length > 1 && <span>...</span>}
                </div>
              )}
            </div>
          ),
          action: (
            <div className={styles.languageCon}>
              {user_permissions?.update && (
                <div
                  style={{ cursor: "pointer" }}
                  // onClick={(e) => handleEditclick(e, item)}
                  onClick={() => handleRedirect(item?.slug)}
                >
                  <img src={editIcon} />
                </div>
              )}
              {user_permissions?.delete && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteorg(item?.slug)}
                >
                  <img src={delete_icon} />
                </div>
              )}
            </div>
          ),
        };
      });

      console.log("MOdifiedArray", modifiedArray);
      setCourseList([...modifiedArray]);
    } catch (error) {
      console.log("FFFFFFsSS", error);
    }
  };

  const getCourseAnalyticalData = async () => {
    try {
      const fetchData = await token_api.get(`course/course_batch_count`);

      if (fetchData?.status !== 200) {
        throw new Error("Error Fetching analytical data");
      }
      let dashBoardData = fetchData?.data?.data;
      console.log("FETCHANALYTICALS", fetchData?.data?.data);

      // let modifiedData = courseDashBoardData?.map((item) => {
      //   if (item?.id === 1) {
      //     return {
      //       ...item,
      //       value: dashBoardData?.courses,
      //     };
      //   } else if (item?.id === 2) {
      //     return {
      //       ...item,
      //       value: dashBoardData?.batches,
      //     };
      //   }
      // });
      setCourseDashBoardData(dashBoardData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCourseAnalyticalData();
    getInstructor();
    getCourseDays();
  }, []);

  useEffect(() => {
    if (tabValue == 1) {
      const timeOut = setTimeout(() => {
        let filterObject = {};

        filterObject.ordering = "index";

        if (pageState) {
          filterObject.page = pageState;
        }
        if (filters?.q) {
          filterObject.q = filters?.q;
        }
        // if (filters?.location) {
        //   filterObject.location = filters?.location[0]?.id; //Filtering based on city_new_id key
        //   // filterObject.city = 30;
        // }

        if (filters?.type) {
          const ids = filters?.type?.map((item) => item?.id);
          filterObject.type = ids;
        }

        // if (filters?.language) {
        //   const ids = filters?.language?.map((item) => item?.id);
        //   filterObject.language = ids;
        // }

        // if (filters?.mode) {
        //   const ids = filters?.mode?.map((item) => item?.id);
        //   filterObject.mode = ids;
        // }

        if (filters?.rating) {
          const ids = filters?.rating?.map((item) => item?.id);
          filterObject.rating = ids;
        }

        // if (filters?.max) {
        //   filterObject.max = filters?.max;
        // }
        // if (filters?.min !== undefined) {
        //   filterObject.min = filters?.min;
        // }

        getCourseListing(filterObject);
      }, 500);

      return () => clearTimeout(timeOut);
    } else {
      // setPageState(1);
      const timeOut = setTimeout(() => {
        let filterObject = {};
        if (pageState) {
          filterObject.page = pageState;
        }
        if (filters?.q) {
          filterObject.q = filters?.q;
        }
        if (filters?.location) {
          filterObject.location = filters?.location[0]?.id; //Filtering not implemented. Filtering based on city_new_id
          // filterObject.city = 130;
        }

        // if (filters?.type) {
        //   const ids = filters?.type?.map((item) => item?.id);
        //   filterObject.type = ids;
        // }

        if (filters?.language) {
          const ids = filters?.language?.map((item) => item?.id);
          filterObject.language = ids;
        }
        if (filters?.course) {
          const ids = filters?.course?.map((item) => item?.id);
          filterObject.course_id = ids;
        }

        if (filters?.mode) {
          const ids = filters?.mode?.map((item) => item?.id);
          filterObject.mode = ids;
        }

        // if (filters?.rating) {
        //   const ids = filters?.rating?.map((item) => item?.id);
        //   filterObject.rating = ids;
        // }

        if (filters?.max) {
          filterObject.max = filters?.max;
        }
        if (filters?.min !== undefined) {
          filterObject.min = filters?.min;
        }

        getBatchListing(filterObject);
      }, 500);

      return () => clearTimeout(timeOut);
    }
  }, [
    tabValue,
    pageState,
    filters?.q,
    filters?.location,
    filters?.type,
    filters?.language,
    filters?.mode,
    filters?.rating,
    filters?.max,
    filters?.min,
    filters?.course,
  ]);

  console.log(filters, "courseList");

  const getCourseTypes = () => {
    token_api
      .get(`/course/types`)
      .then((response) => {
        let newdata = response?.data?.data;
        setTypes(newdata);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLanguage = () => {
    token_api
      .get(`/course/language`)
      .then((response) => {
        let newdata = response?.data?.data;
        setLanguageList(newdata);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCourses = () => {
    token_api
      .get(`/course`)
      .then((response) => {
        const newdata = response?.data?.data.map((item) => ({
          id: item?.id,
          title: item?.name,
        }));
        setCourses(newdata);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(courses, "jatstahshhsh");
  //Batch Listing...
  const getBatchListing = async (filterObject) => {
    try {
      const fetchList = await token_api.get(`course/batches`, {
        params: { ...filterObject },
        paramsSerializer: {
          indexes: null,
        },
      });
      console.log(fetchList, "tableBodyddd");
      if (fetchList?.status !== 200) {
        throw new Error("Network Error");
      }
      setPaginationData(fetchList?.data);

      let modifiedArray = fetchList?.data?.data?.map((item, index) => {
        console.log(item, "fetchList");
        // const { bookingaddonservice } = item;

        return {
          id: (
            <div
              style={{ fontSize: "12px" }}
              // style={{ cursor: "pointer" }}
              // onClick={() => handleRedirect(item?.slug)}
            >
              {item?.id}
            </div>
          ),
          name: (
            <div
              style={{ fontSize: "12px" }}
              // style={{ cursor: "pointer" }}
              // onClick={() => handleRedirect(item?.slug)}
            >
              {" "}
              {item?.name}
            </div>
          ),
          course_name: (
            <p style={{ fontSize: "12px" }}>
              {item?.course_detail ? item?.course_detail?.name : "N.A"}
            </p>
          ),
          language: (
            <p style={{ fontSize: "12px" }}>{`${
              item?.language?.title || "N.A"
            }`}</p>
          ),
          location: (
            <p style={{ fontSize: "12px" }}>
              {item?.location?.line_1},{item?.location?.line_2},
              {item?.location?.area},{item?.location?.city},
              {item?.location?.state},{item?.location?.country},
              {item?.location?.pincode}
            </p>
          ),

          start_date: (
            <p style={{ fontSize: "12px" }}>{`${item?.start_date || "N.A"}`}</p>
          ),
          end_date: (
            <p style={{ fontSize: "12px" }}>{`${item?.end_date || "N.A"}`}</p>
          ),

          total_seats: (
            <p style={{ fontSize: "12px" }}>{`${
              item?.total_seats || "N.A"
            }`}</p>
          ),
          price: (
            <p style={{ fontSize: "12px" }}>{`Rs.${item?.mrp || "N.A"}`}</p>
          ),
          instructors: (
            <div className={styles.instructorCon}>
              {item?.instructors?.length > 0 && (
                <div className={styles.instructor} style={{ fontSize: "12px" }}>
                  {item.instructors[0].name}
                  {item.instructors.length > 1 && <span>...</span>}
                </div>
              )}
            </div>
          ),

          actions: (
            <div
              className={styles.actionsContainer}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {user_permissions?.update && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleEditBatch(item, index)}
                >
                  <img src={editIcon} />
                </div>
              )}
              {user_permissions?.delete && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteBatch(item?.id)}
                >
                  <img src={deleteIcon} />
                </div>
              )}
            </div>
          ),
        };
      });

      console.log("MOdifiedArray", modifiedArray);
      setBatchListing([...modifiedArray]);
    } catch (error) {
      console.log("FFFFFFsSS", error);
    }
  };

  //Batch DEleting...

  const handleDeleteBatch = (batchId) => {
    token_api
      .delete(`/course/batches/${batchId}/`)
      .then((response) => {
        if (
          response?.status === 201 ||
          response?.status === 204 ||
          response?.status === 200
        ) {
          alert("Course Batch Deleted Successfully!");
          getBatchListing();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Batch Editing

  const handleEditBatch = (batch, index) => {
    console.log(batch, "jattahttsts");
    const {
      id,
      name,
      course,
      course_detail,
      start_date,
      start_time,
      end_time,
      end_date,
      descriptions,
      mrp,
      selling_price,
      discount,
      days_of_weeks,
      language,
      mode,
      instructors,
      total_seats,
      location,
      slots,
      instructor,
      zoom_link,
    } = batch;
    let modifiedMode = "";
    let modifiedLanguage = "";
    let modifiedInstructors = [];
    let modifiedLocation = "";
    let modifiedCourse = [];
    if (mode && mode[0]) {
      modifiedMode = [mode[0]];
    } else {
      modifiedMode = batchMode.filter((item) => item?.id === mode);
    }

    if (language && language[0]) {
      modifiedLanguage = [language[0]];
    } else {
      modifiedLanguage = languageList.filter(
        (item) => item?.id === language?.id
      );
    }
    // if (location && location[0]) {
    //   modifiedLocation = [location[0]];
    // }
    if (location) {
      modifiedLocation = {
        id: location?.id,
        title: `${location?.line_1}, ${location?.line_2}, ${location?.area}, ${location?.city_new}, ${location?.state} ,${location?.country}`,
      };
    } else {
      modifiedLocation = locationList.filter(
        (item) => item?.id === location?.id
      );
    }

    if (course) {
      modifiedCourse = courses.filter((item) => item?.id === course);
    }

    if (instructors && instructors[0]) {
      modifiedInstructors = instructors?.map((item) => {
        return {
          id: item?.id,
          name: item?.name,
          qualification: item?.qualification,
          experience: item?.experience,
        };
      });
    }

    console.log(instructors, "abcde");

    setbatchForm({
      ...batchForm,
      id,
      name,
      start_date: dayjs(start_date),
      end_date: dayjs(end_date),
      start_time,
      end_time,
      descriptions,
      mrp,
      selling_price,
      discount,
      days_of_weeks: days_of_weeks?.map((item) => {
        return { id: item?.id, title: item?.weekday || item?.title };
      }),
      language: modifiedLanguage,
      mode: modifiedMode,
      course: modifiedCourse,
      instructors: modifiedInstructors,
      total_seats: total_seats,
      location: [modifiedLocation],
      flying_slot_id: slots || [],
      zoom_link,
    });
    setFormErrors({});
    setOpenBatchDrawer(true);
  };

  console.log(batchForm, "newBatchFormmmmmm");

  useEffect(() => {
    const isOffline = batchForm.mode.length === 1 && batchForm.mode[0].id === 2;
    setIsOfflineMode(isOffline);
  }, [batchForm.mode]);

  function getFlyingSlotsforOffiline() {
    console.log(batchForm?.start_date, "startdate");

    const startdate = formatDate(batchForm?.start_date);
    const enddate = formatDate(batchForm?.end_date);

    token_api
      .get(`/course/flying_slots?start_date=${startdate}&end_date=${enddate}`)
      .then((response) => {
        console.log(response?.data?.data, "flyingdata");
        setFlyingSlotList(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (isOfflineMode && batchForm?.start_date && batchForm?.end_date) {
      getFlyingSlotsforOffiline();
    }
  }, [isOfflineMode, batchForm?.start_date, batchForm?.end_date]);

  useEffect(() => {
    // Ensure flying_start_date is not before start_date
    if (batchForm.flying_start_date && batchForm.start_date) {
      const startDate = formatDate(batchForm.start_date);
      const flyingStartDate = formatDate(batchForm.flying_start_date);

      if (flyingStartDate < startDate) {
        setbatchForm((prevBatchForm) => ({
          ...prevBatchForm,
          flying_start_date: dayjs(batchForm.start_date),
        }));
      }
    }

    // Ensure flying_end_date is not after end_date
    if (batchForm.flying_end_date && batchForm.end_date) {
      const endDate = formatDate(batchForm.end_date);
      const flyingEndDate = formatDate(batchForm.flying_end_date);

      if (flyingEndDate > endDate) {
        setbatchForm((prevBatchForm) => ({
          ...prevBatchForm,
          flying_end_date: dayjs(batchForm.end_date),
        }));
      }
    }
  }, [
    batchForm.start_date,
    batchForm.end_date,
    batchForm.flying_start_date,
    batchForm.flying_end_date,
  ]);

  //Batch Add....

  const handleAddBatch = () => {
    let isDescriptionsEmpty;

    if (editorRef.current) {
      const content = editorRef.current.getContent();
      batchForm.descriptions = content;

      // Set a boolean value based on whether the content is empty or not
      isDescriptionsEmpty = !content.trim();
    }

    // validate before adding batch

    // const copyBatch = batchForm;

    // copyBatch.mode = batchForm?.mode[0]?.id;
    // copyBatch.language = batchForm?.language?.map((item) => item?.id);
    // copyBatch.instructors = batchForm?.instructors?.map((item) => item?.id);
    // copyBatch.days = batchForm?.days?.map((item) => item?.id);
    // console.log("BATCHEDITORLOG", copyBatch, batchForm);

    validateBatch(batchForm, isOfflineMode, switchValue, isDescriptionsEmpty)
      .then((res) => {
        if (res === "success") {
          console.log("FDDS", res);
          // after validations success , 3 situations
          // 1-new courses new batches - no api call
          // 2-present course  new batch - add batch api
          // 3-presnt course batch edit - batch patch api
          if (batchForm?.id) {
            // update by batch patch api callss
            // hit patch api , 3 situation
            const language_id = batchForm?.language[0]?.id;
            const location_id = batchForm?.location[0]?.id;
            const instructor = batchForm?.instructors?.map((item) => item?.id);
            const days_of_weeks_process = batchForm?.days_of_weeks?.map(
              (item) => item?.id
            );
            const con_start_date = formatDate(batchForm?.start_date);
            const con_end_date = formatDate(batchForm?.end_date);
            const fly_start_date = formatDate(batchForm?.flying_start_date);
            const fly_end_date = formatDate(batchForm?.flying_end_date);

            let updatedBatchForm;

            if (isOfflineMode) {
              if (switchValue) {
                const {
                  start_date,
                  end_date,
                  flying_start_date,
                  flying_end_date,
                  flying_slot_id,
                  instructors,
                  days_of_weeks,
                  ...rest
                } = batchForm;
                updatedBatchForm = {
                  ...rest,
                  flying_start_date: fly_start_date,
                  flying_end_date: fly_end_date,
                  flying_slot_id: batchForm?.flying_slot_id[0]?.id,
                };
              } else {
                const {
                  start_date,
                  end_date,
                  flying_slot_id,
                  flying_start_date,
                  flying_end_date,
                  instructors,
                  days_of_weeks,
                  ...rest
                } = batchForm;
                updatedBatchForm = {
                  ...rest,
                  flying_slot_id: batchForm?.flying_slot_id[0]?.id,
                };
              }
            } else {
              const {
                start_date,
                end_date,
                flying_start_date,
                flying_end_date,
                flying_slot_id,
                instructors,
                days_of_weeks,
                ...restBatchForm
              } = batchForm;
              updatedBatchForm = restBatchForm;
            }

            token_api
              .patch(`course/batches/${batchForm?.id}/`, {
                ...updatedBatchForm,
                days_of_week: days_of_weeks_process,
                mode: updatedBatchForm?.mode[0]?.id,
                course: batchForm?.course[0]?.id,
                language_id,
                instructor_id: instructor,
                start_date: con_start_date,
                end_date: con_end_date,
                ...(updatedBatchForm?.mode?.[0]?.id !== 1 && {
                  location_id: location_id,
                }),
                ...(updatedBatchForm?.mode?.[0]?.id !== 2 && {
                  zoom_link: batchForm.zoom_link,
                }),
              })
              .then((response) => {
                if (
                  response?.status === 200 ||
                  response?.status === 201 ||
                  response?.status === 204
                ) {
                  alert("Batch Updated Successfully!");
                  setbatchForm({
                    id: null,
                    name: "",
                    course: [],
                    start_date: null,
                    end_date: "",
                    start_time: "",
                    end_time: null,
                    mrp: "",
                    selling_price: "",
                    total_seats: "",
                    location: [],
                    discount: "",
                    days_of_weeks: [],
                    mode: [],
                    language: [],
                    instructors: [],
                    descriptions: "",
                    flying_start_date: null,
                    flying_end_date: null,
                    flying_slot_id: [],
                    zoom_link: "",
                  });
                  setFormErrors({});
                  handleCloseDrawer();
                  getBatchListing();
                }
              })
              .catch((err) => {});
          } else {
            // batch add logic
            // 2- situation - add batch api
            const language_id = batchForm?.language[0]?.id;
            const location_id = batchForm?.location[0]?.id;
            const instructor =
              batchForm?.instructors.length > 0
                ? batchForm?.instructors?.map((item) => item?.id)
                : [];
            const days_of_weeks_process = batchForm?.days_of_weeks?.map(
              (item) => item?.id
            );

            const con_start_date = formatDate(batchForm?.start_date);
            const con_end_date = formatDate(batchForm?.end_date);
            const fly_start_date = formatDate(batchForm?.flying_start_date);
            const fly_end_date = formatDate(batchForm?.flying_end_date);

            let updatedBatchForm;

            if (isOfflineMode) {
              if (switchValue) {
                const {
                  start_date,
                  end_date,
                  flying_start_date,
                  flying_end_date,
                  flying_slot_id,
                  instructors,
                  days_of_weeks,
                  ...rest
                } = batchForm;
                updatedBatchForm = {
                  ...rest,
                  flying_start_date: fly_start_date,
                  flying_end_date: fly_end_date,
                };
              } else {
                const {
                  start_date,
                  end_date,
                  flying_slot_id,
                  flying_start_date,
                  flying_end_date,
                  instructors,
                  days_of_weeks,
                  ...rest
                } = batchForm;
                updatedBatchForm = {
                  ...rest,
                  flying_slot_id: batchForm?.flying_slot_id[0]?.id,
                };
              }
            } else {
              const {
                start_date,
                end_date,
                flying_start_date,
                flying_end_date,
                flying_slot_id,
                instructors,
                days_of_weeks,
                ...restBatchForm
              } = batchForm;
              updatedBatchForm = restBatchForm;
            }

            console.log(updatedBatchForm, "userBatchForm");

            token_api
              .post(`course/batches/`, {
                ...updatedBatchForm,
                course: batchForm?.course[0]?.id,
                mode: updatedBatchForm?.mode?.[0]?.id,
                language_id: language_id,
                instructor_id: instructor,
                days_of_week: days_of_weeks_process,
                start_date: con_start_date,
                end_date: con_end_date,
                ...(updatedBatchForm?.mode?.[0]?.id !== 1 && {
                  location_id: location_id,
                }),
                ...(updatedBatchForm?.mode?.[0]?.id !== 2 && {
                  zoom_link: batchForm.zoom_link,
                }),
              })
              .then((response) => {
                if (
                  response?.status === 200 ||
                  response?.status === 201 ||
                  response?.status === 204
                ) {
                  alert("Batch Created Successfully!");
                  setbatchForm({
                    id: null,
                    name: "",
                    course: [],
                    start_date: null,
                    end_date: "",
                    start_time: "",
                    end_time: null,
                    mrp: "",
                    selling_price: "",
                    total_seats: "",
                    location: [],
                    discount: "",
                    days_of_weeks: [],
                    mode: [],
                    language: [],
                    instructors: [],
                    descriptions: "",
                    flying_start_date: null,
                    flying_end_date: null,
                    flying_slot_id: [],
                    zoom_link: "",
                  });
                  setFormErrors({});
                  handleCloseDrawer();
                  getBatchListing();
                }
              })
              .catch((err) => {});
          }
        }
      })
      .catch((err) => {
        console.log(err, "iamurerror");
        setFormErrors(err);
      });
  };

  useEffect(() => {
    // getLocationList();
    getCourseTypes();
    getLanguage();
    getCourses();
  }, []);

  const handleDeleteFilter = (id, selectName) => {
    setfilters({
      ...filters,
      [selectName]: filters?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  const handleChange = (e, type, selectName, multiple) => {
    console.log(e, type, selectName, multiple, "TYTYY");
    if (type === "select" && selectName && multiple) {
      setbatchForm({
        ...batchForm,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setbatchForm({
        ...batchForm,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setbatchForm({
        ...batchForm,
        [name]: value,
      });
    }

    // const { name, target } = e.target;
  };
  const handleDeleteChip = (id, selectName) => {
    console.log("DELETE", id, selectName, batchForm?.[selectName]);
    setbatchForm({
      ...batchForm,
      [selectName]: batchForm?.[selectName]?.filter((chip) => chip?.id !== id),
    });
    // (chips) => chips.filter((chip) => chip?.id !== id)
  };

  const handleFilter = (e, type, selectName, multiple) => {
    console.log("HANDLERSS", e, type);
    if (type === "select" && selectName && multiple) {
      setfilters({
        ...filters,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setfilters({
        ...filters,
        [selectName]: [e],
      });
    }
  };

  const handleAutocompleteChangeforInstructor = (selectedValues) => {
    const newItemId = selectedValues[selectedValues?.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    console.log(newItemId, selectedValues, "hariSelectedValues");
    if (isIdAlreadyPresent) {
      alert("Instructor already included");
    } else {
      setbatchForm({
        ...batchForm,
        instructors: selectedValues,
      });
    }
  };

  const handleSliderChange = (value) => {
    // Handle the slider value change
    setfilters((prev) => ({
      ...prev,
      max: value,
      min: 40000,
    }));
  };

  console.log(filters?.max, "useval");
  const handleReset = () => {
    setfilters((prev) => ({
      ...prev,
      max: 0,
      q: "",
      city: [],
      location: [],
      type: [],
      language: [],
      mode: [],
      min: 0,
      rating: [],
    }));
  };

  //  //For multi  selection....

  // const handleAutocompleteChangeforCourse = (selectedValues) => {
  //   const newItemId = selectedValues[selectedValues.length - 1]?.id;
  //   const isIdAlreadyPresent = selectedValues
  //     .slice(0, -1)
  //     .some((item) => item.id === newItemId);

  //   if (isIdAlreadyPresent) {
  //     alert("Course already included");
  //   } else {
  //     setbatchForm({
  //       ...batchForm,
  //       ["course"]: selectedValues,
  //     });
  //   }
  // };

  //For single selection....

  const handleAutocompleteChangeforCourse = (selectedValues) => {
    if (selectedValues.length > 0) {
      const newItem = selectedValues[selectedValues.length - 1];

      setbatchForm({
        ...batchForm,
        course: [newItem], // Store only the most recent selection as a single item array
      });
    } else {
      // Handle the case where no selection is made, if needed
      setbatchForm({
        ...batchForm,
        course: [],
      });
    }
  };

  const handleAutocompleteChangeforCourseFilter = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Course already included");
    } else {
      setfilters({
        ...filters,
        ["course"]: selectedValues,
      });
    }
  };

  console.log(filters, "jahstaufilterssss");

  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };

  const getLocationList = (value) => {
    let apiEndpoint = "/course/location";

    if (value) {
      apiEndpoint += `?q=${value}`;
    }
    token_api
      .get(apiEndpoint)
      .then((res) => {
        if (res.data.data) {
          console.log(res.data.data, "newresdata");
          let userloc = res.data.data?.map((info) => ({
            id: info?.id,
            city_new_id: info?.city_new_id,
            title: `${info?.line_1}, ${info?.line_2}, ${info?.area}, ${info?.city_new}, ${info?.state}, ${info?.country}`,
          }));
          setLocationList(userloc);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleChangeforSearch = (event, newValue) => {
    console.log(newValue, "newValueee");
    setTypedValue(newValue);
    setfilters((prev) => ({
      ...prev,
      location: [newValue],
    }));
  };
  useEffect(() => {
    let timer;

    if (typedValue) {
      timer = setTimeout(() => {
        getLocationList(typedValue);
      }, 500);
    } else {
      getLocationList();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [typedValue]);

  const handleChangeforSearchLocation = (event, newValue) => {
    console.log(newValue, "newValueee");
    setLocationTypedValue(newValue);
    setbatchForm((prev) => ({
      ...batchForm,
      location: [newValue],
    }));
  };
  useEffect(() => {
    let timer;

    if (locationtypedValue) {
      timer = setTimeout(() => {
        getLocationList(locationtypedValue);
      }, 500);
    }

    // else {
    //   getLocationList();
    // }
    return () => {
      clearTimeout(timer);
    };
  }, [locationtypedValue]);
  console.log(courseDashBoardData, "courseDashBoardData");

  //Courses Export Functionality....

  const handleExportCourseData = async () => {
    try {
      const response = await axios.get(`${base_url}course/export`);
      const excelUrl = response.data.data.data.excel_url;
      const link = document.createElement("a");
      link.href = excelUrl;
      link.setAttribute("download", `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();

      if (
        response?.status === 200 ||
        response?.status === 201 ||
        response?.status === 204
      ) {
        alert("Course export file downloaded successfully!");

        getCourseListing();
      }
    } catch (error) {
      console.error("Error exporting the file", error);
    }
  };

  //Batches Export Functionality....

  const handleExportCourseBatchesData = async () => {
    try {
      const response = await axios.get(`${base_url}course/batches/export`);
      const excelUrl = response.data.data.data.excel_url;
      const link = document.createElement("a");
      link.href = excelUrl;
      link.setAttribute("download", `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();

      if (
        response?.status === 200 ||
        response?.status === 201 ||
        response?.status === 204
      ) {
        alert("Batches export file downloaded successfully!");

        getCourseListing();
      }
    } catch (error) {
      console.error("Error exporting the file", error);
    }
  };
  return (
    <Container>
      <div className="flexBetween">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>{" "}
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        </div>

        {/* {user_permissions.write && (
          <Link to="/lms/courses/create-course">
            <Button variant="contained" className="btnLinearGradient">
              + New Course{" "}
            </Button>
          </Link>
        )} */}
      </div>

      <div className={styles.dashBoardCon}>
        {/* cards sections */}

        {courseCardData?.map((item) => {
          console.log(item, "itemlist");
          return (
            <DashboardCard
              cardTitle={item?.title}
              cardIcon={item?.icon}
              primaryGardient={item?.primaryGradient}
              secondaryGradient={item?.secondarayGradient}
              hint={item?.hint}
              value={item?.value || "--"}
              countvalue={courseDashBoardData}
              present={true}
            />
          );
        })}
      </div>
      <div className={styles.tabContainer}>
        <CustomTabs3
          tabHead={tabData}
          currentTab={tabValue}
          setTabVal={(newTabValue) => {
            setTabValue(newTabValue);
            setPageState(1);
          }}
        />
        <div>
          {user_permissions?.write && tabValue == 1 && (
            <Link to="/lms/courses/create-course">
              <Button variant="contained" className="btnLinearGradient">
                + New Course{" "}
              </Button>
            </Link>
          )}

          {user_permissions?.write && tabValue == 2 && (
            <Button
              variant="contained"
              className="btnLinearGradient"
              onClick={handleAddBatchOpenDrawer}
            >
              + New Batch{" "}
            </Button>
          )}
        </div>
      </div>

      {tabValue === 1 && (
        <FilterContainer>
          <CustomSearchFilter
            placeholder="Search by course title..."
            value={filters?.q}
            onChange={(e) => {
              setfilters({ ...filters, q: e });
            }}
          />

          <CustomSelectChip
            label={"Filter by Course Type"}
            listArray={types}
            minWidth={"175px"}
            multiple={false}
            name="type"
            value={filters?.type}
            onChange={handleFilter}
            onDelete={handleDeleteFilter}
          />

          <CustomSelectChip
            label={"Filter by Rating"}
            listArray={courseRating}
            name="rating"
            value={filters?.rating}
            multiple={false}
            minWidth={"175px"}
            onChange={handleFilter}
            onDelete={handleDeleteFilter}
          />

          {/* <CustomButton
            text={"ADVANCE FILTERS"}
            btnIcon={<img src={filterIcon} alt="adv-filter" />}
            // onClick={() => setAdvFilter((prev) => !prev)}
            // maxWidth="170px"
          /> */}
          <CustomButton
            text={"Export"}
            maxWidth="110px"
            onClick={handleExportCourseData}
          />
        </FilterContainer>
      )}

      {tabValue === 2 && (
        <FilterContainer
          style={{ maxHeight: "40px", marginTop: "20px" }}
          // className={`${styles.marginTopBox}`}
        >
          <CustomSearchFilter
            placeholder="Search by batch name, course name..."
            value={filters?.q}
            onChange={(e) => {
              setfilters({ ...filters, q: e });
            }}
          />
          {/* <CustomSelectChip
  label={"Filter by Location"}
  listArray={locationList}
  minWidth={"175px"}
  multiple={false}
  name="city"
  value={filters?.city}
  onChange={handleFilter}
  onDelete={handleDeleteChip}
/> */}

          <SearchAndDropdown
            label="Filter by Location"
            value={filters?.location}
            onChange={handleChangeforSearch}
            listing={locationList}
            typedValue={typedValue}
            setTypedValue={setTypedValue}
          />

          <CourseComboBoxCom
            value={filters?.course}
            onChange={handleAutocompleteChangeforCourseFilter}
          />

          {/* <CustomSelectChip
    label={"Filter by Course Type"}
    listArray={types}
    minWidth={"175px"}
    multiple={false}
    name="type"
    value={filters?.type}
    onChange={handleFilter}
    onDelete={handleDeleteFilter}
  /> */}

          {/* <CustomSelect label={"Date"} listArray={[]} minWidth={"150px"} /> */}
          {/* <CustomDate /> */}

          <CustomButton
            text={"ADVANCE FILTERS"}
            btnIcon={<img src={filterIcon} alt="adv-filter" />}
            onClick={() => setAdvFilter((prev) => !prev)}
            // maxWidth="170px"
          />
          <CustomButton
            text={"Export"}
            maxWidth="110px"
            onClick={handleExportCourseBatchesData}
          />
        </FilterContainer>
      )}

      {tabValue === 2 && advFilter && (
        <>
          <FilterContainer style={{ maxHeight: "40px", marginTop: "20px" }}>
            <div style={{ width: "70%" }}>
              <CustomSelectChip
                label={"Filter by Language"}
                listArray={languageList}
                name="language"
                value={filters?.language}
                multiple={false}
                maxWidth={"175px"}
                onChange={handleFilter}
                onDelete={handleDeleteFilter}
              />
            </div>

            <div style={{ width: "70%" }}>
              <CustomSelectChip
                label={"Filter by Mode"}
                listArray={batchMode}
                name="mode"
                value={filters?.mode}
                multiple={false}
                minWidth={"175px"}
                onChange={handleFilter}
                onDelete={handleDeleteFilter}
              />
            </div>
            {/* <div style={{ width: "70%" }}>
              <CustomSelectChip
                label={"Filter by Rating"}
                listArray={courseRating}
                name="rating"
                value={filters?.rating}
                multiple={false}
                minWidth={"175px"}
                onChange={handleFilter}
                onDelete={handleDeleteFilter}
              />
            </div> */}

            <div style={{ margin: "0px 10px" }}>
              <CustomSlider
                style={{ width: "100%" }}
                min={40000}
                max={80000}
                step={10}
                defaultValue={filters?.max}
                onChange={handleSliderChange}
                primaryColor="#E95E33"
                label="₹"
              />
            </div>

            <CustomButton
              text={"Reset All"}
              // btnIcon={<img src={filterIcon} alt="adv-filter" />}
              onClick={() => handleReset()}
              maxWidth="170px"
            />
          </FilterContainer>
        </>
      )}

      {tabValue == 1 && (
        <>
          {" "}
          <div className={styles.tableContainer}>
            <CustomTable
              columns={tableColumns}
              data={courseList}
              handleClick={handleRedirect}
              tableFor="courseListing"
            />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        </>
      )}

      {tabValue == 2 && (
        <>
          {" "}
          <div className={styles.tableContainer}>
            <CustomTable
              columns={tableBatchColumns}
              data={batchListing}
              handleClick={handleRedirect}
              tableFor="batchListing"
            />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        </>
      )}

      <DrawerComp
        width="60%"
        open={openBatchDrawer}
        onClose={handleCloseDrawer}
        anchor={"right"}
      >
        <DrawerContainer style={{ marginTop: "0px", marginBottom: "10px" }}>
          <DrawerTitle
            text={batchForm?.id ? "Edit Batch" : `Add Batch`}
            handleCancelDrw={handleCloseDrawer}
          />

          <div
            style={{ marginTop: "10px", rowGap: "15px" }}
            className={styles.commonFieldsBox}
          >
            <InputFields
              label="Batch Name"
              required
              name="name"
              value={batchForm?.name}
              onChange={handleChange}
              error={formErrors?.name}
              helperText={formErrors?.name}
            />

            <div>
              <CourseComboBoxCom
                value={batchForm?.course}
                onChange={handleAutocompleteChangeforCourse}
              />
              {formErrors?.course && (
                <p style={{ color: "red" }}>Please select course</p>
              )}
            </div>
            <CustomSelectChip
              multiple="true"
              label="Language"
              name="language"
              onChange={handleChange}
              onDelete={handleDeleteChip}
              value={batchForm?.language}
              listArray={languageList}
              error={formErrors?.language}
            />
            <CustomSelectChip
              multiple={false}
              label="Mode"
              name="mode"
              onChange={handleChange}
              onDelete={handleDeleteChip}
              value={batchForm?.mode}
              listArray={batchMode}
              error={formErrors?.mode}
            />
            <CustomSelectChip
              multiple="true"
              label="Days"
              name="days_of_weeks"
              onChange={handleChange}
              onDelete={handleDeleteChip}
              value={batchForm?.days_of_weeks}
              listArray={courseDaysList}
              error={formErrors?.days_of_weeks}
            />

            {/* <CustomDate
          label="Start Date"
          handleChange={handleChange}
          name="start_date"
          value={batchForm?.start_date}
          error={formErrors?.start_date}
          helperText={formErrors?.start_date ? "Enter batch start date" : ""}
        />
        <CustomDate
          label="End Date"
          handleChange={handleChange}
          name="end_date"
          value={batchForm?.end_date}
          error={formErrors?.end_date}
          helperText={formErrors?.end_date ? "Enter batch end date" : ""}
        /> */}

            <CustomDatePicker
              label="Start date"
              value={batchForm?.start_date}
              onChange={(newValue) =>
                setbatchForm({
                  ...batchForm,
                  start_date: newValue,
                })
              }
              error={formErrors?.start_date}
            />

            <CustomDatePicker
              label="End date"
              value={batchForm?.end_date}
              onChange={(newValue) =>
                setbatchForm({
                  ...batchForm,
                  end_date: newValue,
                })
              }
              error={formErrors?.end_date}
            />

            <DurationPicker
              style={{ width: "100%" }}
              label="Start Time"
              size="small"
              name="start_time"
              onChange={handleChange}
              value={batchForm?.start_time}
              error={formErrors?.start_time}
              // required
              // error={startTimeCheck}
              helperText={formErrors?.start_time ? "Enter start time" : ""}
            />
            <DurationPicker
              style={{ width: "100%" }}
              label="End Time"
              size="small"
              name="end_time"
              onChange={handleChange}
              value={batchForm?.end_time}
              error={formErrors?.end_time}
              // required
              // error={startTimeCheck}
              helperText={formErrors?.end_time ? "Enter end time" : ""}
            />
            {/* <CustomSelectChip
          multiple="true"
          label="Instructors"
          name="instructors"
          onChange={handleChange}
          onDelete={handleDeleteChip}
          value={batchForm?.instructors}
          listArray={instructorList}
        /> */}
            <SearchInstructorAndDropDown
              value={batchForm?.instructors}
              onChange={handleAutocompleteChangeforInstructor}
              error={formErrors?.instructors}
            />
            {/* {formErrors?.instructors && (
          <span style={{ color: "red" }}>Please select instructor.</span>
        )} */}
            <InputFields
              label="Total Seats"
              required
              name="total_seats"
              value={batchForm?.total_seats}
              onChange={handleChange}
              error={formErrors?.total_seats}
              helperText={formErrors?.total_seats}
            />
            {batchForm?.mode[0] && batchForm?.mode[0]?.id != 1 && (
              // <CustomSelectChip
              //   multiple={false}
              //   label="Location"
              //   name="location"
              //   onChange={handleChange}
              //   onDelete={handleDeleteChip}
              //   value={batchForm?.location}
              //   listArray={locationList}
              //   error={formErrors?.location}
              // />
              <SearchAndDropdown
                label="Select Location"
                value={batchForm?.location[0]}
                onChange={handleChangeforSearchLocation}
                listing={locationList}
                typedValue={locationtypedValue}
                setTypedValue={setLocationTypedValue}
              />
            )}

            {isOfflineMode && (
              <CustomizedSwitches
                initialValue={switchValue}
                label="Create New Flying Slot for this Batch."
                onChange={handleSwitchChange}
              />
            )}

            {isOfflineMode && switchValue && (
              <>
                {/* <CustomDate
              label="Start Date"
              handleChange={handleChange}
              name="flying_start_date"
              value={batchForm?.flying_start_date}
            />
            <CustomDate
              label="End Date"
              handleChange={handleChange}
              name="flying_end_date"
              value={batchForm?.flying_end_date}
            /> */}

                <CustomDatePicker
                  label="Flying start date"
                  value={batchForm?.flying_start_date}
                  onChange={(newValue) =>
                    setbatchForm({
                      ...batchForm,
                      flying_start_date: newValue,
                    })
                  }
                  error={formErrors?.flying_start_date}
                />
                <CustomDatePicker
                  label="Flying end date"
                  value={batchForm?.flying_end_date}
                  onChange={(newValue) =>
                    setbatchForm({
                      ...batchForm,
                      flying_end_date: newValue,
                    })
                  }
                  error={formErrors?.flying_end_date}
                />
              </>
            )}

            {isOfflineMode &&
              !switchValue &&
              batchForm?.start_date &&
              batchForm?.end_date && (
                <StartAndEndDateDropdown
                  multiple={false}
                  label="Select Flying Class Slot"
                  name="flying_slot_id"
                  onChange={handleChange}
                  onDelete={handleDeleteChip}
                  value={batchForm?.flying_slot_id}
                  listArray={flyingSlotList}
                  error={formErrors?.flying_slot_id}
                />
              )}
            {/* <InputFields
          label=""
          required
          name="name"
          value={batchForm?.name}
          onChange={handleChange}
        /> */}
            {/* <InputFields
          label="Batch Name"
          required
          name="name"
          value={batchForm?.name}
          onChange={handleChange}
        /> */}
          </div>

          <div style={{ marginTop: "10px" }}>
            <TextEditor
              editorRef={editorRef}
              initialValue={batchForm?.descriptions}
              height={300}
            />

            <small style={{ marginTop: "5px", color: "red" }}>
              {formErrors?.descriptions &&
                "Please fill the descriptions for the batch"}
            </small>
          </div>

          <div style={{ marginTop: "10px" }} className={styles.commonFieldsBox}>
            <InputFields
              onKeyPress={handleKeyPress}
              label="Selling Price"
              required
              name="selling_price"
              value={batchForm?.selling_price}
              onChange={handleChange}
              error={formErrors?.selling_price}
              helperText={formErrors?.selling_price}
            />
            <InputFields
              onKeyPress={handleKeyPress}
              label="MRP"
              required
              name="mrp"
              value={batchForm?.mrp}
              onChange={handleChange}
              error={formErrors?.mrp}
              helperText={formErrors?.mrp}
            />
            <InputFields
              onKeyPress={handleKeyPress}
              label="Discount"
              name="discount"
              value={batchForm?.discount}
              onChange={handleChange}
            />
            {batchForm?.mode[0] && batchForm?.mode[0]?.id != 2 && (
              <InputFields
                label="Zoom link"
                required
                name="zoom_link"
                value={batchForm?.zoom_link}
                onChange={handleChange}
                error={formErrors?.zoom_link}
                helperText={formErrors?.zoom_link}
              />
            )}
          </div>

          {/* paste here the commented code  */}
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              className={`${styles.gradientColor}`}
              onClick={handleAddBatch}
            >
              Save
            </Button>
          </div>
        </DrawerContainer>
      </DrawerComp>
    </Container>
  );
};

export default CoursesList;

const tableColumns = [
  // {
  //   id: "checkBox",
  //   label: "",
  // },
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Course",
  },
  {
    id: "course_type",
    label: "Course Type",
  },
  {
    id: "language",
    label: "Language",
  },
  {
    id: "duration",
    label: "Duration",
  },
  {
    id: "seats",
    label: "Seats",
  },
  {
    id: "location",
    label: "Location",
  },
  {
    id: "batches",
    label: "Batches",
  },
  {
    id: "lowest_price",
    label: "Starting Price",
  },
  {
    id: "instructors",
    label: "Instructors",
  },
  {
    id: "action",
    label: "Action",
  },
];

const tableBatchColumns = [
  // {
  //   id: "checkBox",
  //   label: "",
  // },
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Batch",
  },
  {
    id: "course_name",
    label: "Course Name",
  },

  {
    id: "language",
    label: "Language",
  },
  {
    id: "location",
    label: "Location",
  },
  {
    id: "start_date",
    label: "Start Date",
  },

  {
    id: "end_date",
    label: "End Date",
  },
  {
    id: "total_seats",
    label: "Total Seats",
  },
  // {
  //   id: "batches",
  //   label: "Batches",
  // },
  {
    id: "price",
    label: "Price",
  },
  {
    id: "instructors",
    label: "Instructors",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

const tabData = [
  {
    id: 1,

    title: "Active Course",
  },
  {
    id: 2,

    title: "Batches",
  },
];

const tableBodyData = [
  {
    id: "CCD2E",
    course: "Small RPC Training",
    language: ["En", "Tel"],
    duration: "30 Mins",
    seats: 10,
    location: "Mumbai",
    batches: 5,
    price: "₹ 55,000",
    instructors: ["MS", "HG"],
  },
  {
    id: "CCD2E",
    course: "Small RPC Training",
    language: ["En", "Tel"],
    duration: "30 Mins",
    seats: 10,
    location: "Mumbai",
    batches: 5,
    price: "₹ 55,000",
    instructors: ["MS", "HG"],
  },
  {
    id: "CCD2E",
    course: "Small RPC Training",
    language: ["En", "Tel"],
    duration: "30 Mins",
    seats: 10,
    location: "Mumbai",
    batches: 5,
    price: "₹ 55,000",
    instructors: ["MS", "HG"],
  },
];

const batchMode = [
  {
    title: "Online",
    id: 1,
  },
  {
    title: "Offline",
    id: 2,
  },
  {
    title: "Hybrid",
    id: 3,
  },
];

const courseRating = [
  { id: 1, title: "Rating 1" },
  { id: 2, title: "Rating 2" },
  { id: 3, title: "Rating 3" },
  { id: 4, title: "Rating 4" },
  { id: 5, title: "Rating 5" },
];
