import React, { useContext, useEffect, useState } from "react";
import styles from "./banner.module.css";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CustomTabs3 from "../../Component/CustomTabsNew/CustomTabs3";
import filterIcon from "../../Assets/filterIcon.png";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import CustomButton from "../../Component/CustomButton/CustomButton";
import CustomSlider from "../../Component/CustomSlider/CustomSlider";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import { Button, useTheme, Checkbox, colors } from "@mui/material";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import AddBannerForm from "./AddBannerForm";
import statusicon1 from "../../Assets/activeS.svg";
import statusicon2 from "../../Assets/inactivS.svg";
import editicon from "../../Assets/edit_icon.svg";
import deleteicon from "../../Assets/delete_icon.svg";
import { token_api } from "../../Utils/network";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";

const Banners = () => {
  let navigate = useNavigate();

  const { user_permissions } = useContext(UserCredsContext);
  const [tabValue, setTabValue] = useState(1);
  const [filters, setfilters] = useState({ max: 0, min: undefined, q: "" });
  const [advFilter, setAdvFilter] = useState(false);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [bannerDrawer, setBannerDrawer] = useState(false);
  const [bannerList, setBannerList] = useState([]);
  const [types, setTypes] = useState([]);
  const [bannerDetails, setBannerDetails] = useState({});
  const [tableBodyData, setTableBodyData] = useState([]);
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Banner</div>,
  ];

  const handleDeleteChip = (id, selectName) => {
    setfilters({
      ...filters,
      [selectName]: filters?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  const handleFilter = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setfilters({
        ...filters,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setfilters({
        ...filters,
        [selectName]: [e],
      });
    }
  };

  const handleAddBanner = () => {
    setBannerDrawer(true);
  };

  const handleReset = () => {
    setfilters((prev) => ({
      ...prev,
      max: 0,
      q: "",
      city: [],
      type: [],
      language: [],
      mode: [],
      min: 0,
      rating: [],
    }));
  };

  const handleRedirect = (id) => {};

  const handleCloseDrawer = () => {
    // seteditBatchIndex(null);
    setBannerDetails({});
    setBannerDrawer(false);
  };

  const handleEditBanner = (editObject) => {
    setBannerDetails(editObject);
    // const { stationed_at, drone, id, registration_date, serial_no, ...rest } =
    //   editObject;
    // setDronDetails({
    //   id,
    //   registration_date,
    //   serial_no,
    //   stationed_at_id: stationed_at,
    //   drone_id: drone,
    // });
    // setOpen(true);
    setBannerDrawer(true);
  };

  const handleDeleteBanner = (deleteobj) => {
    token_api
      .delete(`base/banners/${deleteobj}/`)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
          getBannerData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBannerData = (finalFilters) => {
    token_api
      .get(`base/banners`, { params: { ...finalFilters } })
      .then((res) => {
        if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
          // setTableBodyData(res?.data?.data)
          setPaginationData(res?.data);
          let modifiedArray = res?.data?.data?.map((item) => {
            return {
              // checkBox: (
              //     <Checkbox
              //         checked={false}
              //         // onChange={handleChange}
              //         inputProps={{ "aria-label": "controlled" }}
              //     />
              // ),
              id: (
                <div
                  style={{ cursor: "pointer", fontSize: "12px" }}
                  // onClick={() => handleRedirect(item?.slug)}
                >
                  {item?.id}
                </div>
              ),
              web: (
                <div className={styles.imgContainer}>
                  {item?.web ? <img src={item?.web} /> : "NA"}
                </div>
              ),
              tab: (
                <div className={styles.imgContainer}>
                  {item?.tab ? <img src={item?.tab} /> : "NA"}
                </div>
              ),
              mobile: (
                <div className={styles.imgContainer}>
                  {item?.mobile ? <img src={item?.mobile} /> : "NA"}
                </div>
              ),
              redirection_display: (
                <div className={styles.rediText}>
                  <p style={{ fontSize: "12px" }}>{item?.redirection_str}</p>
                  <p
                    className={styles.rediSubtext}
                    style={{ fontSize: "12px" }}
                  >
                    {item?.detail?.title}
                  </p>
                </div>
              ),
              status_display: (
                <div>
                  {item?.status === 1 ? (
                    <div
                      className={`${styles.activeStatus} ${styles.statusbox} `}
                    >
                      <img src={statusicon1} />
                      <p style={{ fontSize: "12px" }}> {item?.status_str} </p>
                    </div>
                  ) : (
                    <div
                      className={`${styles.inActiveStatus} ${styles.statusbox}`}
                    >
                      <img src={statusicon2} />
                      <p style={{ fontSize: "12px" }}>{item?.status_str}</p>
                    </div>
                  )}
                </div>
              ),
              created_modified_info: (
                <div>
                  <p style={{ margin: "0", fontSize: "12px" }}>
                    {item?.created_modified_info?.modified_date}
                  </p>
                  <p style={{ margin: "0", fontSize: "12px" }}>
                    ({item?.created_modified_info?.modified_time})
                  </p>
                </div>
              ),
              actions: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "10px",
                    justifyContent: "center",
                  }}
                >
                  {user_permissions?.update && (
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => handleEditBanner(item)}
                      src={editicon}
                      // {/* Edit */}
                    />
                  )}
                  {user_permissions?.delete && (
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDeleteBanner(item?.id)}
                      src={deleteicon}
                      // Delete
                    />
                  )}
                </div>
              ),
            };
          });

          setBannerList([...modifiedArray]);
        }
        // let tableBodyData = res?.data?.data
      })

      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBannerData();
  }, []);

  useEffect(() => {
    const timout = setTimeout(() => {
      const finalFilters = {};
      finalFilters.page = pageState;
      finalFilters.full_name = filters?.q;

      getBannerData(finalFilters);
    }, 600);
    return () => clearTimeout(timout);
  }, [pageState, filters]);

  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };
  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "30px 0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>{" "}
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        </div>

        {user_permissions?.write && (
          <Button
            onClick={handleAddBanner}
            variant="contained"
            className={`${styles.gradientColor} ${styles.AddButton}`}
          >
            + New Banner
          </Button>
        )}
      </div>
      {/* <div className={styles.tabContainer}>
                <CustomTabs3 tabHead={tabData} currentTab={tabValue} setTabVal={setTabValue} />
            </div> */}

      {/* <FilterContainer
                style={{ maxHeight: "40px", marginTop: "20px" }}
                className={`${styles.marginTopBox}`}
            >
                <CustomSearchFilter
                    value={filters?.q}
                    onChange={(e) => {
                        setfilters({ ...filters, q: e });
                    }}
                />
                <CustomSelectChip
                    label={"Filter by Redirection"}
                    // listArray={locationList}
                    minWidth={"175px"}
                    multiple={false}
                    name="city"
                    value={filters?.city}
                    onChange={handleFilter}
                    onDelete={handleDeleteChip}
                />

                <CustomSelectChip
                    label={"Filter by Date"}
                    listArray={types}
                    minWidth={"175px"}
                    multiple={false}
                    name="type"
                    value={filters?.type}
                    onChange={handleFilter}
                    onDelete={handleDeleteChip}
                />

                <CustomSelectChip
                    label={"Filter by Status"}
                    listArray={types}
                    minWidth={"175px"}
                    multiple={false}
                    name="type"
                    value={filters?.type}
                    onChange={handleFilter}
                    onDelete={handleDeleteChip}
                />


                <CustomButton
                    text={"ADVANCE FILTERS"}
                    btnIcon={<img src={filterIcon} alt="adv-filter" />}
                    onClick={() => setAdvFilter((prev) => !prev)}

                />
                <CustomButton
                    text={"Export"}
                    maxWidth="110px"
                    onClick={() => setOpenDrawer(true)}
                />
            </FilterContainer>

            {advFilter && (
                <>
                    <FilterContainer style={{ maxHeight: "40px", marginTop: "20px" }}>
                        <div style={{ width: "70%" }}>
                            <CustomSelectChip
                                label={"Filter by Language"}
                                // listArray={languageList}
                                name="language"
                                value={filters?.language}
                                multiple={false}
                                maxWidth={"175px"}
                                onChange={handleFilter}
                                onDelete={handleDeleteChip}
                            />
                        </div>

                        <div style={{ width: "70%" }}>
                            <CustomSelectChip
                                label={"Filter by Mode"}
                                // listArray={batchMode}
                                name="mode"
                                value={filters?.mode}
                                multiple={false}
                                minWidth={"175px"}
                                onChange={handleFilter}
                                onDelete={handleDeleteChip}
                            />
                        </div>
                        <div style={{ width: "70%" }}>
                            <CustomSelectChip
                                label={"Filter by Rating"}
                                // listArray={courseRating}
                                name="rating"
                                value={filters?.rating}
                                multiple={false}
                                minWidth={"175px"}
                                onChange={handleFilter}
                                onDelete={handleDeleteChip}
                            />
                        </div>

                        <div style={{ margin: "0px 10px" }}>
                            <CustomSlider
                                style={{ width: "100%" }}
                                min={50000}
                                max={100000}
                                step={10}
                                defaultValue={filters?.max}
                                onChange={handleSliderChange}
                                primaryColor="#E95E33"
                            />
                        </div>

                        <CustomButton
                            text={"Reset All"}
                            // btnIcon={<img src={filterIcon} alt="adv-filter" />}
                            onClick={() => handleReset()}
                            maxWidth="170px"
                        />
                    </FilterContainer>
                </>
            )} */}

      {tabValue == 1 && (
        <>
          {" "}
          <div className={styles.tableContainer}>
            <CustomTable
              columns={tableColumns}
              data={bannerList}
              handleClick={handleRedirect}
              tableFor="courseListing"
            />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        </>
      )}

      {tabValue == 2 && (
        <>
          {" "}
          <div>Active Data</div>
        </>
      )}

      {tabValue == 3 && (
        <>
          <div>Inactive Data</div>
        </>
      )}

      <DrawerComp
        width="45%"
        open={bannerDrawer}
        onClose={handleCloseDrawer}
        anchor={"right"}
      >
        <AddBannerForm
          handleCancelDrw={handleCloseDrawer}
          getBannerData={getBannerData}
          bannerDetails={bannerDetails}
        />
      </DrawerComp>
    </Container>
  );
};

export default Banners;

const tabData = [
  {
    id: 1,
    title: "All",
  },
  {
    id: 2,
    title: "Active",
  },
  {
    id: 3,
    title: "Inactive",
  },
];

const tableColumns = [
  {
    id: "checkBox",
    label: "",
  },
  {
    id: "id",
    label: "Banner ID",
  },
  {
    id: "web",
    label: "Website",
  },
  {
    id: "tab",
    label: "Tab",
  },
  {
    id: "mobile",
    label: "Mobile",
  },
  {
    id: "redirection_display",
    label: "Redirection",
  },
  {
    id: "status_display",
    label: "Status",
  },
  {
    id: "created_modified_info",
    label: "Modified On",
  },
  {
    id: "actions",
    label: "",
  },
];
